import React, { useState, ChangeEvent, FormEvent, useCallback, useEffect, useRef } from 'react';
import { PlusCircle, Save, MinusCircle, RotateCw, Trash, List, XCircle, ChevronUp, ArrowDown, ArrowUp } from 'lucide-react';
import { ChevronDown } from 'lucide-react';




interface Subproceso {
  id: number;
  nombre: string;
  color: string;
}
interface FormData {
  codigo: string;
  nombreProceso: string;
  anio: string;
  region: string;
  numeroRegion: string;
  comuna: string;
  numeroProceso: string;
  etapa: string;
  subprocesos: Subproceso[];
}

interface Region {
  region: string;
  numero: string;
  comunas: string[];
}

interface LabelMessageProps {
  type: 'success' | 'error';
  message: string;
}


const LabelMessage: React.FC<LabelMessageProps> = ({ type, message }) => {
  const [progress, setProgress] = useState(100);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 31); // Ajusta este valor para cambiar la velocidad de la animación

    return () => clearInterval(timer);
  }, []);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
  const borderColor = type === 'success' ? 'border-green-600' : 'border-red-600';
  const textColor = 'text-white';

  return (
    <div className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded relative mb-4 border-l-4 overflow-hidden`} role="alert">
      <span className="block sm:inline">{message}</span>
      <div 
        className={`absolute bottom-0 left-0 h-1 ${type === 'success' ? 'bg-green-700' : 'bg-red-700'}`} 
        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
      ></div>
    </div>
  );
};




const AdminModule = () => {
  const [formData, setFormData] = useState<FormData>({
    codigo: '',
    nombreProceso: '',
    anio: '',
    region: '',
    numeroRegion: '',
    comuna: '',
    numeroProceso: '',
    etapa: '',
    subprocesos: [{ id: 1, nombre: '', color: '#000000' }], // Default subproceso with black color
  });

  const [regions, setRegions] = useState<Region[]>([]);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [selectedComuna, setSelectedComuna] = useState<string>('');
  
  const comunaSelectRef = useRef<HTMLSelectElement>(null); // Crear un ref para el select de comuna
  const [message, setMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);




  const resetForm = () => {
    setFormData({
      codigo: '',
      nombreProceso: '',
      anio: '',
      region: '',
      numeroRegion: '',
      comuna: '',
      numeroProceso: '',
      etapa: '',
      subprocesos: [{ id: 1, nombre: '', color: '#000000' }], // Default subproceso with black color
    });
    setSelectedComuna('');
    setSelectedRegion('');
    setSelectedProcess('');
  };




  
  const decimalToRoman = (num: number): string => {
    const romanNumerals = [
      { value: 1000, symbol: 'M' },
      { value: 900, symbol: 'CM' },
      { value: 500, symbol: 'D' },
      { value: 400, symbol: 'CD' },
      { value: 100, symbol: 'C' },
      { value: 90, symbol: 'XC' },
      { value: 50, symbol: 'L' },
      { value: 40, symbol: 'XL' },
      { value: 10, symbol: 'X' },
      { value: 9, symbol: 'IX' },
      { value: 5, symbol: 'V' },
      { value: 4, symbol: 'IV' },
      { value: 1, symbol: 'I' }
    ];

    let roman = '';
    for (const { value, symbol } of romanNumerals) {
      while (num >= value) {
        roman += symbol;
        num -= value;
      }
    }
    return roman;
  };

  function obtenerCodigo(cadena: string): string {
    // Encuentra la posición del primer punto
    const puntoPos: number = cadena.indexOf('.');

    if (puntoPos === -1) {
        // Si no hay punto en la cadena, devuelve la cadena completa
        return cadena;
    }

    // Parte de la cadena antes del punto
    const parteAntesDelPunto: string = cadena.substring(0, puntoPos);

    // Parte de la cadena después del punto
    const subcadenaDespuesDelPunto: string = cadena.slice(puntoPos + 1);
    
    // Encuentra la posición del primer espacio en blanco después del punto
    const espacioPos: number = subcadenaDespuesDelPunto.search(/\s/);

    let parteDespuesDelPunto: string;

    if (espacioPos === -1) {
        // Si no hay espacio en blanco, toma toda la subcadena después del punto
        parteDespuesDelPunto = subcadenaDespuesDelPunto.trim();
    } else {
        // Si hay un espacio, toma la subcadena desde el punto hasta el espacio
        parteDespuesDelPunto = subcadenaDespuesDelPunto.substring(0, espacioPos).trim();
    }
    
    // Devuelve la concatenación de la parte antes del punto y la parte después del punto
    return parteAntesDelPunto + '.' + parteDespuesDelPunto;
}


  const generateCodigo = useCallback((data: FormData): string => {
    const { nombreProceso, anio, numeroRegion, numeroProceso, etapa } = data;
    const nombreProcesoAbreviada = nombreProceso ? nombreProceso.substring(0, 3).toUpperCase() : '';
    const anioAbreviado = anio ? anio.substring(2) : '';
    const etapaRoman = decimalToRoman(parseInt(etapa, 10) || 0);
    return `${anioAbreviado}${numeroRegion}${numeroProceso}.${etapaRoman}`;
  }, []);

 
  useEffect(() => {
    if (formData.region && formData.anio) {
  //   fetchNumeroProceso();
    }
  }, [formData.region, formData.anio]);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await import('../../data/regiones.json');
        const data = response.default as { regiones: Region[] };
        setRegions(data.regiones);
        setLoadingRegions(false);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    const region = regions.find(region => region.comunas.includes(selectedComuna));
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        comuna: selectedComuna,
        region: region.region,
        numeroRegion: region.numero
      }));
    }
  }, [selectedComuna, regions]);

  useEffect(() => {
    if (selectedRegion) {
      const region = regions.find(r => r.numero === selectedRegion);
      if (region) {
        setFormData(prevData => ({
          ...prevData,
          region: region.region,
          numeroRegion: region.numero
        }));
      }
    }
  }, [selectedRegion, regions]);
 const handleComunaChange = (event: ChangeEvent<HTMLSelectElement>) => {
  const comuna = event.target.value;
  setSelectedComuna(comuna);

  const region = regions.find(region => region.comunas.includes(comuna));
  if (region) {
    setSelectedRegion(region.numero);
    setFormData(prevData => {
      const updatedData = {
        ...prevData,
        comuna: comuna,
        region: region.region,
        numeroRegion: region.numero
      };
      if (updatedData.nombreProceso || updatedData.anio || updatedData.numeroRegion || updatedData.numeroProceso || updatedData.etapa) {
        updatedData.codigo = generateCodigo(updatedData);
      }
      if (updatedData.region && updatedData.anio) {
      //  fetchNumeroProceso();
      }
      return updatedData;
    });
  } else {
    setSelectedRegion('');
    setFormData(prevData => {
      const updatedData = {
        ...prevData,
        comuna: comuna,
        region: '',
        numeroRegion: ''
      };
      if (updatedData.nombreProceso || updatedData.anio || updatedData.numeroRegion || updatedData.numeroProceso || updatedData.etapa) {
        updatedData.codigo = generateCodigo(updatedData);
      }
      return updatedData;
    });
  }
};


  const handleRegionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const regionNumero = event.target.value;
    setSelectedRegion(regionNumero);
  
    const region = regions.find(r => r.numero === regionNumero);
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        region: region.region,
        numeroRegion: region.numero
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        region: '',
        numeroRegion: ''
      }));
    }
  };

  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [processes, setProcesses] = useState<string[]>([]);
  const [selectedProcess, setSelectedProcess] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);

  const fetchProcesses = async () => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };

   
  const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
    setMessage({ type, message });
    setTimeout(() => {
      setMessage(null);
    }, duration);
  };
  const fetchProcessDetails = async (codigo: string) => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_details.php?codigo=${codigo}`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log(JSON.stringify(data));
      setSelectedComuna(data.data?.comuna);
      const event = { target: { value: data.data?.comuna } } as React.ChangeEvent<HTMLSelectElement>;
      handleComunaChange(event);
      
      const subprocesosObjects = (data.data?.subprocesos || []).map((subproceso: { subproceso: string, color: string }, index: number) => ({
        id: index + 1,
        nombre: subproceso.subproceso,
        color: subproceso.color || '#000000' // Use the color from the database or default to black
      }));
  
      setFormData(prevData => ({
        ...prevData,
        codigo: data.data?.codigo || '',
        nombreProceso: data.data?.nombreProceso || '',
        anio: data.data?.anio || '',
        comuna: data.data?.comuna || '',
        region: data.data?.region || '',
        numeroProceso: `0${data.data?.numeroProceso || ''}`,
        etapa: data.data?.etapa || '',
        subprocesos: subprocesosObjects,
      }));
    } catch (error) {
      console.error('Error fetching process details:', error);
    }
  };


  useEffect(() => {
    fetchProcesses();

  }, []);

  const handleProcessSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const process = event.target.value;
    setSelectedProcess(process);
    const codigo = obtenerCodigo(process);
    fetchProcessDetails(codigo);
  };


  const handleAddSubproceso = () => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: [
        ...prevData.subprocesos,
        { id: prevData.subprocesos.length + 1, nombre: '', color: '#000000' }
      ],
    }));
  };
  
  const handleRemoveSubproceso = (id: number) => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.filter(sp => sp.id !== id),
    }));
  };

  
  const handleSubprocesoChange = (id: number, field: 'nombre' | 'color', value: string) => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.map(sp =>
        sp.id === id ? { ...sp, [field]: value } : sp
      ),
    }));
  };

  
  const predefinedSubprocesos = ['Terreno', 'Permiso de Edificación', 'Proyectos Ingeniería', 'Licitación', 'Desarrollo de Proyectos', 'Estudio de Impacto Ambiental'];
  


  



  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData(prevData => {
      const updatedData = { ...prevData, [name]: value };

      if (name === 'region' || name === 'anio') {
        if (updatedData.region && updatedData.anio) {
        //  fetchNumeroProceso();
        }
      }

      if (name === 'nombreProceso' || name === 'anio' || name === 'numeroRegion' || name === 'numeroProceso' || name === 'etapa') {
        updatedData.codigo = generateCodigo(updatedData);
      }

      return updatedData;
    });
  }, [generateCodigo]);



  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nombreProceso || !formData.anio || !formData.region || !formData.numeroRegion || !formData.numeroProceso || !formData.etapa) {
      showLabelMessage('error', 'Por favor, complete todos los campos requeridos.');
      return;
    }
  
    const dataToSend = {
      ...formData,
      subprocesos: formData.subprocesos.filter(sp => sp.nombre.trim() !== '')
    };
    
    // Calcular el número de subprocesos que se eliminarán
    const subprocesosToDelete = formData.subprocesos.length - dataToSend.subprocesos.length;
    
    if (subprocesosToDelete > 0) {
      const confirmDelete = window.confirm(`Advertencia: Se eliminarán ${subprocesosToDelete} subproceso(s) y todos sus datos asociados. ¿Está seguro de que desea continuar?`);
      if (!confirmDelete) {
        return;
      }
    }
    
    const endpoint = 'http://localhost:3000/php/pages/proceso/update_process.php';
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
      
      const data = await response.json();
      
      if (data.success) {
        showLabelMessage('success', 'Datos guardados exitosamente');
        resetForm();
        // Aquí podrías realizar alguna acción adicional si es necesario
      } else {
        showLabelMessage('error', `Hubo un error al guardar los datos: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Hubo un error al guardar los datos');
    }
  }, [formData, resetForm]);

  
  const handleMoveSubproceso = (index: number, direction: 'up' | 'down') => {
    setFormData(prevData => {
      const newSubprocesos = [...prevData.subprocesos];
      if (direction === 'up' && index > 0) {
        [newSubprocesos[index - 1], newSubprocesos[index]] = [newSubprocesos[index], newSubprocesos[index - 1]];
      } else if (direction === 'down' && index < newSubprocesos.length - 1) {
        [newSubprocesos[index], newSubprocesos[index + 1]] = [newSubprocesos[index + 1], newSubprocesos[index]];
      }
      return { ...prevData, subprocesos: newSubprocesos };
    });
  };

  const renderSubprocesoFields = () => {
    return formData.subprocesos.map((subproceso, index) => (
      <div key={subproceso.id} className="flex items-center mb-2">
           <div className="flex ml-2">
           <div className="flex space-x-2">
  <button 
    type="button" 
    onClick={() => handleMoveSubproceso(index, 'up')}
    disabled={index === 0}
    className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <ArrowUp className="w-5 h-5" />
  </button>
  <button 
    type="button" 
    onClick={() => handleMoveSubproceso(index, 'down')}
    disabled={index === formData.subprocesos.length - 1}
    className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === formData.subprocesos.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <ArrowDown className="w-5 h-5" />
  </button>
</div>

        </div>
        <div className="relative ml-5">
          <input
            type="color"
            value={subproceso.color}
            onChange={e => handleSubprocesoChange(subproceso.id, 'color', e.target.value)}
            className="absolute top-0 left-0 opacity-0 w-6 h-6 cursor-pointer"
          />
          <div 
            className="w-6 h-6 rounded-full mr-2 border border-gray-300 cursor-pointer" 
            style={{ backgroundColor: subproceso.color }}
          ></div>
        </div>
        <input
          type="text"
          value={subproceso.nombre}
          onChange={e => handleSubprocesoChange(subproceso.id, 'nombre', e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded text-sm"
          placeholder="Nombre del subproceso"
        />
     
        <button 
          type="button" 
          onClick={() => handleRemoveSubproceso(subproceso.id)} 
          className="ml-2 text-red-500 hover:text-red-700 transition-colors duration-200"
        >
          <MinusCircle className="w-5 h-5" />
        </button>
      </div>
    ));
  };

  const predefinedColors = [
    '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#C39BD3'
  ];

  const generatePastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 80%)`;
  };

  const handleLoadDefaultColors = () => {
    setFormData(prevData => {
      let colorIndex = 0;
      const updatedSubprocesos = prevData.subprocesos.map(sp => {
        if (sp.color === '#000000' || sp.color === '') {
          let newColor;
          if (colorIndex < predefinedColors.length) {
            newColor = predefinedColors[colorIndex];
            colorIndex++;
          } else {
            newColor = generatePastelColor();
          }
          return { ...sp, color: newColor };
        }
        return sp;
      });

      return {
        ...prevData,
        subprocesos: updatedSubprocesos
      };
    });
  };

  const handleClearAllColors = () => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.map(sp => ({
        ...sp,
        color: '#000000'
      }))
    }));
  };


  return (
    <div className="max-w-7xl text-sm mx-auto p-6 bg-white rounded-lg shadow-md">
     

      <div className="mb-6">
        <label htmlFor="processSelect" className="block text-sm font-medium text-gray-700 mb-2">
          Seleccionar Proceso
        </label>
        <select
          id="processSelect"
          value={selectedProcess}
          onChange={handleProcessSelect}
          className="w-full p-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Seleccionar Proceso</option>
          {processes.map((process, index) => (
            <option key={index} value={process}>
              {process}
            </option>
          ))}
        </select>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-6">
          
        <div className="flex flex-col">
            <label htmlFor="codigo" className="text-sm text-lg font-semibold mb-2">Código</label>
            <input
              id="codigo"
              name="codigo"
              type="text"
              value={formData.codigo}
              readOnly
              className="p-2 border text-sm border-gray-300 rounded bg-gray-100 cursor-not-allowed"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="nombreProceso" className="text-sm font-semibold mb-2">Nombre del proceso</label>
            <input
              id="nombreProceso"
              name="nombreProceso"
              type="text"
              value={formData.nombreProceso}
              onChange={handleInputChange}
              className="p-2 text-sm border border-gray-300 rounded"
            />
          </div>

          <div className="flex flex-col ">
            <label htmlFor="anio" className="text-sm font-semibold mb-2">Año</label>
            <input
              id="anio"
              name="anio"
              type="text"
              value={formData.anio}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="comuna" className="text-sm font-semibold mb-2">Comuna</label>
            <select
        value={selectedComuna}
        onChange={handleComunaChange}
        className='p-2  border  border-gray-300 rounded'
      >
        
        <option value="">Seleccione una comuna</option>
        {regions.flatMap(region => region.comunas).map((comuna, index) => (
          <option key={index} value={comuna}>{comuna}</option>
        ))}
      </select>
          </div>

     
          <div className="flex flex-col">
            <label htmlFor="region" className="text-sm font-semibold mb-2">Región</label>
            <select
  value={selectedRegion}
  onChange={(e) => {
    const regionNumero = e.target.value;
    setSelectedRegion(regionNumero);

    const region = regions.find(r => r.numero === regionNumero);
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        region: region.region,
        numeroRegion: region.numero
      }));
    }
  }}
  className='p-2 border border-gray-300 bg-gray-100 rounded  cursor-not-allowed'
  disabled
>
  <option value="" disabled>Región</option>
  {regions.map((region) => (
    <option key={region.numero} value={region.numero}>{region.region}</option>
  ))}
</select>

          </div>


          <div className="flex flex-col">
          <label htmlFor="numeroProceso" className="text-sm font-semibold mb-2">Número de proceso</label>
      <input
        id="numeroProceso"
        name="numeroProceso"
        type="text"
        value={formData.numeroProceso}
        onChange={handleInputChange}
        className="p-2 border border-gray-300 rounded bg-gray-100  cursor-not-allowed"
        disabled // Haz el campo solo lectura si no se debe editar manualmente
      />
          </div>
          <div className="flex flex-col">
          <label htmlFor="etapa" className="text-sm font-semibold mb-2">Etapa</label>
          <input
              id="etapa"
              name="etapa"
              min={1}
              type="number"
              value={formData.etapa}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
        
      </div>
        
        </div>
        <div className="mb-6">
        <div className="justify-between items-center">
        <label className="text-sm font-semibold">Subprocesos</label>
       <div className='flex justify-end'>
        <button 
              type="button" 
              onClick={handleLoadDefaultColors} 
              className="flex items-center text-green-500 hover:text-green-700 transition-colors duration-200"
            >
              <RotateCw className="w-5 h-5 mr-1" /> 
              <span>Cargar colores predefinidos</span>
            </button>
            <button 
                type="button" 
                onClick={handleClearAllColors} 
                className="ml-5 flex items-center text-red-500 hover:text-red-700 transition-colors duration-200"
              >
                <XCircle className="w-5 h-5 mr-1" /> 
                <span>Borrar colores</span>
              </button>
              </div>
      </div>
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            {renderSubprocesoFields()}
            <button 
              type="button" 
              onClick={handleAddSubproceso} 
              className="mt-2 flex items-center text-blue-500 hover:text-blue-700 transition-colors duration-200"
            >
              <PlusCircle className="w-5 h-5 mr-1" /> 
              <span>Añadir subproceso</span>
            </button>
          </div>
        </div>
        {message && (
                 <LabelMessage type={message.type} message={message.message} />

      )}
      
        <div className="flex justify-center mt-6">
          <button type="submit" className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200">
            <Save className="mr-2" /> Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminModule;