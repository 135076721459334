import { parse } from 'path';
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileQuestion } from 'lucide-react';


interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}

interface PlantillaDB {
  id: number;
  nombre: string;
  descripcion: string | null;
  contenido: string;
  tipo: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface Task {
  id: number;
  nombre: string;
  responsable: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  descriptor: string;
  duracion: number;
  organismo: string;
  dependencia: string;
  enabled: boolean;
  isClosed: boolean;
}




interface ProjectTimelineProps {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
  subprocessColor: string;
}

interface SubprocessTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  color: string;
  index: number;
  totalTabs: number;
  activeIndex: number;
}

const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
  label, 
  isActive, 
  onClick, 
  color, 
  index, 
  totalTabs
}) => {
  const [scale, setScale] = useState(1);
  const activeScale = 1.19;
  const fixedOffset = 20; // Offset fijo para todas las pestañas

  useEffect(() => {
    if (isActive) {
      setScale(activeScale);
    } else {
      setScale(1);
    }
  }, [isActive]);

  const buttonStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 30px 0 30px',
    fontSize: '14px',
    fontWeight: 'medium',
    color: isActive ? '#ffffff' : '#115E59',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    transition: 'all 0.3s',
    marginRight: `-${fixedOffset}px`,
    zIndex: isActive ? totalTabs : index,
    whiteSpace: 'nowrap',
  };

  const shapeStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: color,
    opacity: isActive ? 1 : 0.3,
    transition: 'opacity 0.3s',
    clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
  };

  return (
    <motion.button
      onClick={onClick}
      className="relative"
      animate={{ 
        scale,
        zIndex: isActive ? totalTabs : index,
      }}
      whileHover={{ scale: scale * 1.02 }}
      whileTap={{ scale: scale * 0.98 }}
      transition={{ duration: 0.2 }}
      style={buttonStyle}
    >
      <div style={shapeStyle} />
      <span className="relative z-10 px-2">{label}</span>
    </motion.button>
  );
};

interface Agrupador {
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Task[];
 
  
}


const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg ">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Proceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        Este proceso no contiene información, por favor revise la planificación.
      </p>
    </div>
  );
};






const ProjectTimeline: React.FC<ProjectTimelineProps> = ({ selectedProcess, setSelectedProcess, processes, subprocessColor}) => {
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
 // const [processes, setProcesses] = useState<Process[]>([]);
  const [plantillaDB, setPlantillaDB] = useState<PlantillaDB | null>(null);
  const [isProcessLoaded, setIsProcessLoaded] = useState(false);
  const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
  const [processedPlantilla, setProcessedPlantilla] = useState<any>(null);
  const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
  const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const previousProcessRef = useRef<string | null>(null);


  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };




  /*
  useEffect(() => {
    const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);*/


  useEffect(() => {
    if (filteredSubprocesos && filteredSubprocesos.length > 0 && allAgrupadores && allAgrupadores.length > 0) {
      const savedSubprocess = localStorage.getItem('selectedSubprocess');
      
      if (savedSubprocess) {
        const subprocessIndex = filteredSubprocesos.findIndex(sp => sp?.subproceso === savedSubprocess);
  
        if (subprocessIndex !== -1) {
          const agrupadoresForSavedSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === filteredSubprocesos[subprocessIndex]?.subproceso);
          
          setCurrentAgrupadores(agrupadoresForSavedSubprocess);
          setActiveSubprocess(subprocessIndex);
          handleSubprocessChange(subprocessIndex);
        } else {
          const firstSubprocess = filteredSubprocesos[0];
          const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === firstSubprocess?.subproceso);
          
          setCurrentAgrupadores(agrupadoresForFirstSubprocess);
          setActiveSubprocess(0);
          handleSubprocessChange(0);
        }
      } else {
        const firstSubprocess = filteredSubprocesos[0];
        const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador?.subproceso === firstSubprocess?.subproceso);
        
        setCurrentAgrupadores(agrupadoresForFirstSubprocess);
        setActiveSubprocess(0);
        handleSubprocessChange(0);
      }
    } else {
      setActiveSubprocess(-1);
      setCurrentAgrupadores([]);
    }
  }, [filteredSubprocesos, allAgrupadores]);
  
  useEffect(() => {
    if (activeSubprocess !== -1 && filteredSubprocesos && filteredSubprocesos[activeSubprocess]) {
      localStorage.setItem('selectedSubprocess', filteredSubprocesos[activeSubprocess].subproceso);
    }
  }, [activeSubprocess, filteredSubprocesos]);
  
  


  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
      
    }
  }, [selectedProcess]);
/*
  useEffect(() => {
    if (filteredSubprocesos.length > 0 && allAgrupadores.length > 0) {
      setActiveSubprocess(0);
      const firstSubprocess = filteredSubprocesos[0];
      const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => agrupador.subproceso === firstSubprocess.subproceso);
      setCurrentAgrupadores(agrupadoresForFirstSubprocess);
    } else {
      setActiveSubprocess(-1);
      setCurrentAgrupadores([]);
    }
  }, [filteredSubprocesos, allAgrupadores]);*/


  const resetSubprocessState = () => {
    setActiveSubprocess(0);
    setCurrentAgrupadores([]);
    localStorage.removeItem('selectedSubprocess');
  };
  

  const loadProcessData = async (processName: string) => {
    setIsLoading(true);

 // Check if the process has actually changed
 if (processName !== previousProcessRef.current) {
  if (previousProcessRef.current !== null) {
    // Reset subprocess state when switching between processes
    resetSubprocessState();
  }
} else {
  setIsLoading(false);
  return; // Exit early if the process hasn't changed
}


    if (processName === "") {
        resetState();
    setIsLoading(false);
    return;
    } else {
      const selectedProcess = processes.find(p => p.nombreProceso === processName);
      if (selectedProcess) {
        setSelectedNombreProyecto(selectedProcess.nombreProceso);
        setSelectedCodigoProyecto(selectedProcess.codigo);
        
        try {
          const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log('Datos recibidos:', JSON.stringify(data, null, 2));

          const fetchedSubprocesos: Subproceso[] = data.subprocesos
          .filter((sp: any) => sp.agrupadores.some((agr: any) => agr.enabled))
          .map((sp: any) => ({
            id: sp.id,
            subproceso: sp.nombre,
            color: sp.color || getDefaultColor(sp.id)
          }));
          setSubprocesos(fetchedSubprocesos);

          const allAgrupadores: Agrupador[] = data.subprocesos.flatMap((subproceso: any) =>
            subproceso.agrupadores
              .filter((agrupador: any) => agrupador.enabled)
              .map((agrupador: any) => ({
              nombre: agrupador.nombre,
              fecha_inicio: formatDate(getEarliestStartDate(agrupador.tareas)),
              fecha_termino: formatDate(getLatestEndDate(agrupador.tareas)),
              progreso: calculateAverageProgress(agrupador.tareas),
              subproceso: subproceso.nombre,
              enabled: agrupador.enabled

            }))
          );

          setAllAgrupadores(allAgrupadores);

          const filteredSubs = fetchedSubprocesos.filter(subproceso => 
            allAgrupadores.some(agrupador => agrupador.subproceso === subproceso.subproceso)
          );
          setFilteredSubprocesos(filteredSubs);

          if (fetchedSubprocesos.length > 0) {
            setActiveSubprocess(0);
            const firstSubprocess = fetchedSubprocesos[0];
            const agrupadoresForFirstSubprocess = allAgrupadores.filter(agrupador => 
              agrupador.subproceso === firstSubprocess.subproceso && agrupador.enabled
            );
            setCurrentAgrupadores(agrupadoresForFirstSubprocess);
          }

          setIsProcessLoaded(true);
        } catch (error) {
          console.error('Error al cargar el proceso:', error);
          resetState();
        }
      }
    }
    previousProcessRef.current = processName;

    setIsLoading(false);
  };


  // Funciones auxiliares
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  const getEarliestStartDate = (tareas: any[]): string => {
    return tareas.reduce((earliest, tarea) => 
      tarea.fecha_inicio < earliest ? tarea.fecha_inicio : earliest
    , tareas[0]?.fecha_inicio || '');
  };

  const getLatestEndDate = (tareas: any[]): string => {
    return tareas.reduce((latest, tarea) => 
      tarea.fecha_termino > latest ? tarea.fecha_termino : latest
    , tareas[0]?.fecha_termino || '');
  };

  const calculateAverageProgress = (tareas: Task[]): number => {
    if (tareas.length === 0) return 0;
    
    const totalCompletedTasks = tareas.reduce((sum, tarea) => sum + (tarea.isClosed ? 1 : 0), 0);
    return Math.round((totalCompletedTasks / tareas.length) * 100);
  };


  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setFilteredSubprocesos([]);
    setAllAgrupadores([]);
    setCurrentAgrupadores([]);
    setIsProcessLoaded(false);
  };



  
  const handleSubprocessChange = (index: number) => {
    setActiveSubprocess(index);
    const selectedSubprocess = filteredSubprocesos[index];
    const agrupadoresForSubprocess = allAgrupadores.filter(agrupador => agrupador.subproceso === selectedSubprocess.subproceso);
    setCurrentAgrupadores(agrupadoresForSubprocess);
  };


  const lightenColor = (color: string, amount: number): string => {
    if (!color) return '#CCCCCC'; // Color por defecto si no se proporciona
    const num = parseInt(color.replace("#", ""), 16);
    const r = Math.min(255, Math.max(0, (num >> 16) + amount));
    const g = Math.min(255, Math.max(0, ((num >> 8) & 0x00FF) + amount));
    const b = Math.min(255, Math.max(0, (num & 0x0000FF) + amount));
    return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
  };
  
  const darkenColor = (color: string, amount: number): string => {
    return lightenColor(color || '#CCCCCC', -amount);
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0, 
      x: 20, 
      transition: { duration: 0.2 }
    }
  };

  const tableVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0, 
      y: -50, 
      transition: { 
        duration: 0.3,
        when: "afterChildren",
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  };

  return (
    <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    className="bg-white w-full rounded-lg p-3 mx-auto"
  >
    {isLoading ? (
      <div>Cargando...</div>
    ) : isProcessLoaded && filteredSubprocesos.length > 0 ? (
      <>
        <div className="flex mb-8 mt-3">
          {filteredSubprocesos.map((subprocess, index) => (
            <SubprocessTab
              key={index}
              label={subprocess.subproceso}
              isActive={activeSubprocess === index}
              onClick={() => handleSubprocessChange(index)}
              color={subprocess.color}
              index={index}
              totalTabs={filteredSubprocesos.length}
              activeIndex={activeSubprocess}
            />
          ))}
        </div>
        
        {/* Verificamos si hay agrupadores actuales */}
        {currentAgrupadores.length > 0 ? (
          <AnimatePresence mode="wait">
            <motion.div 
              key={activeSubprocess}
              variants={tableVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="overflow-x-auto shadow-lg"
            >
              <table className="min-w-full divide-y divide-gray-200">
                <thead style={{ 
                  backgroundColor: filteredSubprocesos[activeSubprocess]?.color || '#CCCCCC'
                }}>
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">ID</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Agrupador</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Fecha Inicio</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Fecha Término</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Progreso</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentAgrupadores.map((agrupador, index) => (
                    <motion.tr 
                      key={`${agrupador.subproceso}-${index}`}
                      variants={rowVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{agrupador.nombre}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{agrupador.fecha_inicio}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{agrupador.fecha_termino}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${agrupador.progreso}%` }}></div>
                          </div>
                          <span className="text-sm text-gray-500">{agrupador.progreso}%</span>
                        </div>
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </motion.div>
          </AnimatePresence>
        ) : (
          <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
        )}
      </>
    ) : (
      <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
)}
  </motion.div>
);
};

export default ProjectTimeline;