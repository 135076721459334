import React, { useState, useEffect, useRef, useCallback, useMemo, useLayoutEffect } from 'react';
import { ChevronDown, ChevronUp, Minimize, Maximize, FileX, Plus, FileQuestion, X, Upload, Layers } from 'lucide-react';
import { CommonComponentProps } from '../../types/common';
import { motion, AnimatePresence } from 'framer-motion';
import { filter } from 'jszip';

import { debounce } from 'lodash'; // Asegúrate de importar lodash si no lo has hecho


function handleDependenciesAndDates(tasks: Task[], delayedTaskIndex: number): { updatedTasks: Task[], delayInDays: number } {
  if (delayedTaskIndex < 0 || delayedTaskIndex >= tasks.length) {
    console.log(`Índice de tarea inválido: ${delayedTaskIndex}`);
    return { updatedTasks: tasks, delayInDays: 0 };
  }

  const delayedTask = tasks[delayedTaskIndex];
  const delayInDays = delayedTask.delayInDays || 0; // Usamos el delayInDays ya calculado

  console.log(`La tarea ${delayedTask.description} (Posición: ${delayedTaskIndex + 1}) está retrasada por ${delayInDays} día(s).`);

  const updatedTasks = [...tasks];

  function updateDates(taskPosition: number, delay: number) {
    for (let i = taskPosition + 1; i < updatedTasks.length; i++) {
      const task = updatedTasks[i];
      const dependencies = typeof task.dependencia === 'string' 
        ? task.dependencia.split(',').map(Number)
        : task.dependencia ? [Number(task.dependencia)] : [];

      if (dependencies.includes(taskPosition + 1)) {
        const newStartDate = new Date(task.start);
        newStartDate.setDate(newStartDate.getDate() + delay); // Ajustamos la fecha de inicio
        const newEndDate = new Date(task.end);
        newEndDate.setDate(newEndDate.getDate() + delay); // Ajustamos la fecha de fin

        updatedTasks[i] = {
          ...task,
          start: newStartDate.toISOString().split('T')[0],
          end: newEndDate.toISOString().split('T')[0],
        };

        console.log(`Actualizando fechas para la tarea ${task.description} (Posición: ${i + 1}):`);
        console.log(`  Nueva fecha de inicio: ${updatedTasks[i].start}`);
        console.log(`  Nueva fecha de fin: ${updatedTasks[i].end}`);

        updateDates(i, delay); // Recursivamente ajustamos las fechas de las tareas dependientes
      }
    }
  }

  if (delayInDays > 0) {
    updateDates(delayedTaskIndex, delayInDays);
  }

  return { updatedTasks, delayInDays };
}


function analyzeDependencies(tasks: Task[], delayedTaskIndex: number): void {
  if (delayedTaskIndex < 0 || delayedTaskIndex >= tasks.length) {
    console.log(`Índice de tarea inválido: ${delayedTaskIndex}`);
    return;
  }

  const delayedTask = tasks[delayedTaskIndex];
  console.log(`Analizando dependencias para la tarea retrasada: ${delayedTask.description} (Posición: ${delayedTaskIndex + 1})`);

  const affectedTasks: { task: Task; position: number }[] = [];

  function findDependentTasks(taskPosition: number, depth: number = 0) {
    console.log(`${' '.repeat(depth * 2)}Buscando dependencias para tarea en posición: ${taskPosition + 1}`);
    
    tasks.forEach((task, index) => {
      const dependencies = typeof task.dependencia === 'string' 
        ? task.dependencia.split(',').map(Number)
        : task.dependencia ? [Number(task.dependencia)] : [];
      
      if (dependencies.includes(taskPosition + 1)) { // +1 porque las posiciones en la UI empiezan en 1
        if (!affectedTasks.some(t => t.position === index)) {
          console.log(`${' '.repeat((depth + 1) * 2)}Tarea afectada: ${task.description} (Posición: ${index + 1})`);
          affectedTasks.push({ task, position: index });
          findDependentTasks(index, depth + 1); // Buscar dependencias indirectas
        }
      }
    });
  }

  findDependentTasks(delayedTaskIndex);

  console.log(`La tarea ${delayedTask.description} (Posición: ${delayedTaskIndex + 1}) está retrasada.`);
  console.log('Tareas afectadas por el retraso:');
  if (affectedTasks.length === 0) {
    console.log('No se encontraron tareas afectadas.');
  } else {
    affectedTasks.forEach(({ task, position }) => {
      const dependencyType = task.dependencia === `${delayedTaskIndex + 1}` ? 'directamente' : 'indirectamente';
      console.log(`- Tarea ${task.description} (Posición: ${position + 1}) depende ${dependencyType} de la tarea retrasada.`);
    });
  }
}

interface SubprocessTabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  color: string;
  index: number;
  totalTabs: number;
  activeIndex: number;
}



const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
  label, 
  isActive, 
  onClick, 
  color, 
  index, 
  totalTabs
}) => {
  const [scale, setScale] = useState(1);
  const activeScale = 1.185;
  const fixedOffset = 20; // Offset fijo para todas las pestañas



  
  useEffect(() => {
    if (isActive) {
      
      setScale(activeScale);
    } else {
      setScale(1);
    }
  }, [isActive]);

  const buttonStyle: React.CSSProperties = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 30px 0 30px',
    fontSize: '14px',
    fontWeight: 'medium',
    color: isActive ? '#ffffff' : '#115E59',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    transition: 'all 0.3s',
    marginRight: `-${fixedOffset}px`,
    zIndex: isActive ? totalTabs : index,
    whiteSpace: 'nowrap',
  };

  const shapeStyle: React.CSSProperties = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: color,
    opacity: isActive ? 1 : 0.3,
    transition: 'opacity 0.3s',
    clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
  };

  return (
    <motion.button
      onClick={onClick}
      className="relative"
      animate={{ 
        scale,
        zIndex: isActive ? totalTabs : index,
      }}
      whileHover={{ scale: scale * 1.02 }}
      whileTap={{ scale: scale * 0.98 }}
      transition={{ duration: 0.2 }}
      style={buttonStyle}
    >
      <div style={shapeStyle} />
      <span className="relative z-10 px-2">{label}</span>
    </motion.button>
  );
};

interface DateInputProps {
  value: string;
  onChange: (value: string) => void;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange }) => (
  <input
    type="date"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="w-full border rounded px-2 py-1 text-sm"
  />
);
interface NoPlantillaMessageProps {
  navigateToSubComponent: (main: string, sub: string) => void;
}

const NoPlantillaMessage: React.FC<NoPlantillaMessageProps> = ({ navigateToSubComponent }) => {
  const handleEditarPlantillas = () => {
    navigateToSubComponent('ADMINISTRACIÓN', 'Mis plantillas');
  };

  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileX size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Planificación no disponible</h3>
      <p className="text-teal-600 text-center mb-6">Parece que aún no se ha asignado una plantilla para este proceso.</p>
      <button
        onClick={handleEditarPlantillas}
        className="flex items-center bg-teal-500 text-white px-4 py-2 rounded-full hover:bg-teal-600 transition-all duration-300 transform hover:scale-105 active:scale-95"
      >
        <Plus size={20} className="mr-2" />
        Asignar nueva Plantilla
      </button>
    </div>
  );
};


const NoAgrupadoresMessage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center p-10">
      <Layers size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No hay información disponible</h3>
      <p className="text-teal-600 text-center mb-6">Este proceso no contiene información. Por favor revise la planificación.</p>
    </div>
  );
};


interface SmoothCollapseProps {
  isOpen: boolean;
  children: React.ReactNode;
}



const SmoothCollapse: React.FC<SmoothCollapseProps> = ({ isOpen, children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | string>(0);

  useEffect(() => {
    if (isOpen) {
      const contentHeight = contentRef.current?.scrollHeight;
      setHeight(contentHeight || 'auto');
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const containerVariants = {
    open: { 
      height: height,
      opacity: 1,
      transition: { 
        height: { type: "spring", stiffness: 100, damping: 20, duration: 0.3 },
        opacity: { duration: 0.2 }
      }
    },
    collapsed: { 
      height: 0,
      opacity: 0,
      transition: { 
        height: { type: "spring", stiffness: 100, damping: 20, duration: 0.3 },
        opacity: { duration: 1.2 }
      }
    }
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        variants={containerVariants}
        initial="collapsed"
        animate={isOpen ? "open" : "collapsed"}
        exit="collapsed"
        style={{ overflow: 'hidden' }}
      >
        <div ref={contentRef}>
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};



const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg shadow-md">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Subproceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        El subproceso "{subprocessName}" no tiene agrupadores ni tareas definidas.
      </p>
    
    </div>
  );
};



const NoProcesoSeleccionadoMessage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center p-10">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">No se ha seleccionado ningún proceso</h3>
      <p className="text-teal-600 text-center mb-6">Por favor, seleccione un proceso para comenzar.</p>
    </div>
  );
};

interface Task {
  id: number;
  name: string;
  description: string;
  responsible: string;
  progress: number;
  start: string;
  organismo: string;
  duracion: number;
  end: string;
  followUp: boolean;
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  followUpDate: string; // New property for follow-up date
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;

}


enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
}
interface Group {
  name: string;
  expanded: boolean;
  subprocess: string;
  agrupador: string;
  tasks: Task[];
  enabled: boolean; // Nueva propiedad
  descriptor: string; 
  organismo: string;

}


interface ProjectSection {
  title: string;
  sectionType: string;
  processingBody: string;
  isCollapsed: boolean;
  tasks: Task[];
  onChangeSectionType: () => void;
  onChangeProcessingBody: () => void;
  onChangeTask: () => void;
  onToggleCollapse: () => void;
}


interface TaskRowProps {
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  rowColor: string;
  inputColor: string;
  allTasks: Task[];
  isLoading: boolean; // Nueva prop para indicar el estado de carga
  subprocessColor: string;
  index: number; // Added index prop
}
const TaskRow: React.FC<TaskRowProps> = ({ task, onChangeTask, rowColor, inputColor, allTasks, index, isLoading, subprocessColor   }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);



  const handleRowClick = () => {
    setIsModalOpen(true);
  };
  const calculateDaysDelayed = (endDate: string): number => {
    const today = new Date();
    
    // Crear la fecha manualmente en la zona horaria local
    const [year, month, day] = endDate.split('-').map(Number);
    const end = new Date(year, month - 1, day);  // Meses en JavaScript van de 0 a 11
  
    // Ajustar ambos a la medianoche para comparar solo las fechas
    today.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
  
    const diffTime = today.getTime() - end.getTime();
   // alert(`${end}-${endDate}`);  // Mostrar la fecha ajustada y la original
  
    return Math.max(0, Math.ceil(diffTime / (1000 * 3600 * 24)));
  };
  
  const daysDelayed = calculateDaysDelayed(task.end);

  useEffect(() => {
    updateDependentTaskStartDate();
    updateDependentTasksStatus(index);
  }, [task]); // Se ejecuta cada vez que la tarea cambia

  
const updateDependentTasksStatus = (closedTaskIndex: number) => {
  const getMaxEndDate = (dependencies: number[]): Date | null => {
    const dates = dependencies
      .map((depIndex) => {
        const predecessorTask = allTasks[depIndex - 1];
        return predecessorTask ? new Date(predecessorTask.end) : null;
      })
      .filter(Boolean) as Date[];

    return dates.length > 0 ? new Date(Math.max(...dates.map((date) => date.getTime()))) : null;
  };

  const closedTaskPosition = closedTaskIndex + 1;

  allTasks.forEach((task) => {
    if (task.dependencia ) {
      const dependencias =
        typeof task.dependencia === "string"
          ? task.dependencia.split(",").map(Number)
          : [task.dependencia];

      // Verificar si todas las dependencias están cerradas
      const allDependenciesClosed = dependencias.every((depPosition) => {
        const depTask = allTasks[depPosition - 1];
        return depTask;
      });

      if (allDependenciesClosed) {
        const maxDependencyEndDate = getMaxEndDate(dependencias);

        if (maxDependencyEndDate) {
          task.semaphoreStatus = calculateSemaphoreStatus(task.start, task.end);
          console.log(`Tarea ${task.name} ha sido iniciada porque todas sus dependencias han sido cerradas`);
          console.log(`Estado del semáforo: ${task.semaphoreStatus}`);
        }
      }
    }
  });
};
  

  // Función para calcular el estado del semáforo
const calculateSemaphoreStatus = (start: string, end: string): SemaphoreStatus => {
  const today = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (today > endDate) {
    return SemaphoreStatus.Red; // Vencida
  } else if (today >= startDate) {
    const totalDuration = endDate.getTime() - startDate.getTime();
    const remainingTime = endDate.getTime() - today.getTime();
    
    if (remainingTime <= 2 * 24 * 60 * 60 * 1000) {
      return SemaphoreStatus.Orange; // Casi por vencer
    } else if (remainingTime <= 5 * 24 * 60 * 60 * 1000) {
      return SemaphoreStatus.Yellow; // Por vencer pronto
    } else {
      return SemaphoreStatus.Green; // A tiempo
    }
  } else {
    return SemaphoreStatus.Gray; // No iniciada
  }
};

  
  // Función para ajustar las fechas de las tareas dependientes de forma recursiva
  const updateDependentTaskStartDate = () => {
    let tasksUpdated = true;
  
    const getMaxEndDate = (dependencies: number[]): Date | null => {
      const dates = dependencies.map((depIndex: number) => {
        const predecessorTask = allTasks[depIndex - 1];
        return predecessorTask ? new Date(predecessorTask.end) : null;
      }).filter(Boolean) as Date[];
  
      return dates.length > 0 ? new Date(Math.max(...dates.map(date => date.getTime()))) : null;
    };
  
    while (tasksUpdated) {
      tasksUpdated = false;
  
      allTasks.forEach(task => {
        if (task.dependencia && !task.isClosed) {
          const dependencias = typeof task.dependencia === 'string'
            ? task.dependencia.split(',').map(Number)
            : [task.dependencia];
  
          const maxDependencyEndDate = getMaxEndDate(dependencias);
  
          if (maxDependencyEndDate) {
            const newStartDate = new Date(maxDependencyEndDate);
  
            // Verificar si todas las dependencias están cerradas
            const allDependenciesClosed = dependencias.every(depIndex => {
              const depTask = allTasks[depIndex - 1];
              return depTask && depTask.isClosed;
            });
  
            // Si todas las dependencias están cerradas, no agregamos días extra
            // Si alguna dependencia está abierta, agregamos 1 día
            if (!allDependenciesClosed) {
              newStartDate.setDate(newStartDate.getDate() + 1);
            }
  
            if (task.start !== newStartDate.toISOString().split('T')[0]) {
              task.start = newStartDate.toISOString().split('T')[0];
  
              const newEndDate = new Date(newStartDate);
              newEndDate.setDate(newStartDate.getDate() + (task.duracion * 7));
              task.end = newEndDate.toISOString().split('T')[0];
  
              tasksUpdated = true;
            }
          }
        }
      });
    }
  
    console.log('Tareas actualizadas:', allTasks);
  };
  




  
  



  const checkDependencies = (closedTaskGlobalId: number) => {
    // Encontrar la tarea cerrada
    const closedTaskIndex = allTasks.findIndex(task => task.id === closedTaskGlobalId);
    if (closedTaskIndex === -1) {
      console.error(`No se encontró ninguna tarea con el ID global ${closedTaskGlobalId}`);
      return;
    }
  
    const closedTask = allTasks[closedTaskIndex];
    const closedTaskPosition = closedTaskIndex + 1; // Posición basada en 1
  
    console.log(`Verificando dependencias para la tarea "${closedTask.name}" (posición ${closedTaskPosition})`);
  
    // Buscar tareas dependientes
    const dependentTasks = allTasks.filter(task => {
      if (typeof task.dependencia === 'string') {
        // Si la dependencia es una cadena (ej: "1,2,3"), la dividimos en un array de números
        const dependencias = task.dependencia.split(',').map(Number);
        // Verificar si la posición cerrada está en alguna de esas dependencias
        return dependencias.includes(closedTaskPosition);
      } else if (typeof task.dependencia === 'number') {
        // Si la dependencia es un número, compararla directamente
        return task.dependencia === closedTaskPosition;
      }
      return false;
    });
  
    if (dependentTasks.length > 0) {
      const dependentTasksInfo = dependentTasks.map(task => {
        const taskPosition = allTasks.indexOf(task) + 1; // Posición basada en 1
        return `- Tarea "${task.name}" en la posición ${taskPosition}`;
      }).join('\n');
  
    //  alert(`Las siguientes tareas dependen de la tarea "${closedTask.name}" (posición ${closedTaskPosition}):\n\n${dependentTasksInfo}`);
    } else {
    //  alert(`Ninguna tarea depende de la tarea "${closedTask.name}" (posición ${closedTaskPosition})`);
    }
  };
  

  const getSemaphoreStyle = () => {
   
   


   
    if (task.isClosed) {
      return {
        backgroundImage: `repeating-conic-gradient(${subprocessColor} 0% 25%, #fff 0% 50%)`,
        backgroundSize: '10px 10px',
        border: `1px solid ${subprocessColor}`, // Añadimos el borde
        borderRadius: '50%', // Aseguramos que el borde sea circular
      };
    }
    
    
    const semaphoreColor = {
      [SemaphoreStatus.Red]: '#EF4444',
      [SemaphoreStatus.Orange]: '#FB923C',
      [SemaphoreStatus.Yellow]: '#FDE047',
      [SemaphoreStatus.Green]: '#4ADE80',
      [SemaphoreStatus.Gray]: '#E5E7EB'
    }[task.semaphoreStatus];

    
  
    return {
      backgroundColor: semaphoreColor,
    };
  };

  const isFollowUpPending = () => {
    if (!task.followUpDate) return false;
    const today = new Date();
    const followUpDate = new Date(task.followUpDate);
    return followUpDate >= today;
  };

  


  const followUpColor = isFollowUpPending() ? 'bg-red-500' : 'bg-gray-200';

  const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };

  const formatDependencies = (dependencyId: string | number | null): string => {
    if (dependencyId === null) {
      return '';
    }
    return dependencyId.toString();
  };

  return (
    <> 
      <motion.tr
        style={{ 
          backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.05)' : rowColor,
          cursor: 'pointer',
        }}
        whileHover={{ scale: 1.01 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleRowClick}
      >
        <td className="px-6 py-4 whitespace-nowrap text-sm">{index + 1}</td>
        <td className="px-4 py-2 text-sm">{task.description}</td>
        <td className="px-4 py-2 text-sm">{task.responsible}</td>
        <td className="px-4 py-2 text-sm">{formatDate(task.start)}</td>
        <td className="px-4 py-2 text-sm">
          {formatDate(task.end)}
          {daysDelayed > 0 && !task.isClosed && (
            <span className="ml-2 text-red-500">
              (+{daysDelayed} {daysDelayed === 1 ? 'día' : 'días'})
            </span>
          )}
        </td>
        <td className="px-4 py-2 text-center text-sm">{formatDependencies(task.dependencia)}</td>
        <td className="px-4 py-2 text-center">
          <div className={`w-6 h-6 rounded-full ${followUpColor} mx-auto`}></div>
        </td>
        <td className="px-4 py-2 text-center">
     
            
            <div className="w-6 h-6 rounded-full mx-auto" style={getSemaphoreStyle()}></div>
        
        </td>
      </motion.tr>

     <TaskModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        task={task}
        onChangeTask={onChangeTask}
        backgroundColor={inputColor}
        allTasks={allTasks}
        agrupadorId={task.id}
        daysDelayed={daysDelayed}
      />
    </>
  );
};

interface SemaphoreResult {
  status: SemaphoreStatus;
  daysDelayed: number;
}

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
}
const TaskModal: React.FC<TaskModalProps> = ({ 
  isOpen, 
  onClose, 
  task, 
  onChangeTask, 
  backgroundColor,
  allTasks,
  agrupadorId,
  daysDelayed
}) => {

  const [comments, setComments] = useState<string[]>([]);
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(false);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (task.isClosed) return;

    const today = new Date(new Date());
    const selectedDate = new Date(value);

    if (name === 'followUpDate' && selectedDate < today) {
      alert('La fecha de seguimiento no puede ser anterior a hoy.');
      return;
    }

    onChangeTask({ ...task, [name]: value });
  };
  
  const handleAddComment = () => {
    if (newComment.trim()) {
      const timestamp = new Date();
      const commentWithTimestamp = `[${timestamp}] User: ${newComment}`;
      setComments([...comments, commentWithTimestamp]);
      setNewComment('');
    }
  };

  const calculateDaysDelayed = (endDate: string): number => {
    const today = new Date();
    const end = new Date(endDate);
    const diffTime = today.getTime() - end.getTime();
    return Math.ceil(diffTime / (1000 * 3600 * 24));
  };

  const [updateTrigger, setUpdateTrigger] = useState(0);

  const handleCloseTask = async () => {
    if (task.semaphoreStatus === SemaphoreStatus.Gray) {
      alert("No se puede cerrar una tarea que aún no ha iniciado.");
      return;
    }
    const today = new Date().toISOString().split('T')[0];
    const updatedTask = { ...task, isClosed: true, progress: 100, end: today };
    
    try {
      // Actualizar la tarea en la base de datos
      await updateTaskInDatabase(updatedTask);
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado

      // Calcular y actualizar el progreso del agrupador
   //   await updateAgrupadorProgress(task.id);
      
      onChangeTask(updatedTask);
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
    }
  };





  const handleOpenTask = async () => {
    try {
      const today = new Date();
      const newEndDate = new Date(today.getTime() + task.duracion * 7 *  24 * 60 * 60 * 1000); // Calculating new end date based on duration
      
      const updatedTask = { 
        ...task, 
        isClosed: false,
        end: newEndDate.toISOString().split('T')[0]
      };

      // Update task in the database
      await updateTaskInDatabase(updatedTask);
         // Update local state
         onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado
      onChangeTask(updatedTask);
   
    } catch (error) {
      console.error('Error al abrir la tarea:', error);
      alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
    }
  };

  const handleResetFollowUpDate = () => {
    onChangeTask({ ...task, followUpDate: '' });
  };

  const handleSaveChanges = async () => {
    // Actualizar la tarea en la base de datos
 /*   await updateTaskInDatabase(task);
  
    // Calcular el nuevo progreso del agrupador
    const updatedTasks = allTasks.map(t => t.id === task.id ? task : t);
    const closedTasks = updatedTasks.filter(t => t.isClosed).length;
    const newProgress = (closedTasks / updatedTasks.length) * 100;
  
    // Actualizar el progreso del agrupador en la base de datos
    await updateAgrupadorProgress(agrupadorId, newProgress);
  
    // Cerrar el modal y actualizar el estado local
    onChangeTask(task);
    onClose();*/
  };





  const updateTaskInDatabase = async (updatedTask: Task) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: updatedTask.id,
          isClosed: updatedTask.isClosed ? 1 : 0, // Convert boolean to number
          progress: updatedTask.progress,
          end: updatedTask.end
        }),
      });
  
      // ... rest of the function
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };

  

  const formatFollowUpDate = (date: string): string => {
    if (!date) return 'No establecida';
    const followUpDate = new Date(date);
    const today = new Date();
    const diffTime = followUpDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return `Hace ${Math.abs(diffDays)} días`;
    } else if (diffDays === 0) {
      return 'Hoy';
    } else if (diffDays === 1) {
      return 'Mañana';
    } else {
      return `En ${diffDays} días`;
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div
            className="bg-white rounded-lg w-full max-w-2xl"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="text-white p-4 flex justify-between items-center rounded-t-lg" style={{ backgroundColor }}>
              <h2 className="text-xl font-bold">SEGUIMIENTO</h2>
              <button onClick={onClose} className="text-white hover:text-gray-200">
                <X size={24} />
              </button>
            </div>
            <form className="p-6">
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Estado</label>
                {daysDelayed > 0 && !task.isClosed ? (
                  <p className="text-red-600 font-bold">
                    Retrasada por {daysDelayed} día(s)
                  </p>
                ) : (
                  <p className="text-green-600 font-bold">A tiempo</p>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4">
             
                <div>
                  
                  <label className="block text-sm font-medium text-gray-700">Tarea</label>
                  <input
                    type="text"
                    name="description"
                    value={task.description}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Responsable</label>
                  <input
                    type="text"
                    name="responsible"
                    value={task.responsible}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Inicio</label>
                  <input
                    type="date"
                    name="start"
                    value={task.start}
                    onChange={handleDateChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Término</label>
                  <input
                    type="date"
                    name="end"
                    value={task.end}
                    onChange={handleDateChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha de Seguimiento</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="date"
                      name="followUpDate"
                      value={task.followUpDate}
                      onChange={handleInputChange}
                      min={new Date().toISOString().split('T')[0]}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      disabled={task.isClosed}
                    />
                    <button
                      type="button"
                      onClick={handleResetFollowUpDate}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      disabled={task.isClosed}
                    >
                     <X className='text-red-500'></X>
                    </button>
                  </div>
                </div>
                <span className="text-sm text-gray-500 mt-5">
                  {formatFollowUpDate(task.followUpDate)}
                </span>
              </div>
              <div className="mt-4">
                <div className="flex items-center mb-2">
                  <div
                    className="flex items-center cursor-pointer text-indigo-600 hover:text-indigo-800"
                    onClick={() => setShowComments(!showComments)}
                  >
                    <label className="cursor-pointer block text-sm font-medium text-gray-700 mr-2">
                      Comentarios
                    </label>
                    {showComments ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {showComments && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="mt-1 border border-gray-300 rounded-md p-2 max-h-40 overflow-y-auto">
                      {comments.map((comment, index) => (
                        <p key={index} className="text-sm">{comment}</p>
                      ))}
                    </div>
                    <div className="mt-2 flex">
                      <input
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className="flex-grow border focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-l-md"
                        placeholder="Agregar comentario..."
                        disabled={task.isClosed}
                      />
                      <button
                        type="button"
                        onClick={handleAddComment}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={task.isClosed}
                      >
                        Agregar
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="mt-4 flex justify-between">
              <button
                type="button"
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  task.isClosed
                    ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                    : task.semaphoreStatus === SemaphoreStatus.Gray
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-red-600 hover:bg-red-700 focus:ring-red-500"
                }`}
                onClick={task.isClosed ? handleOpenTask : handleCloseTask}
                disabled={!task.isClosed && task.semaphoreStatus === SemaphoreStatus.Gray}
              >
                {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
              </button>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleSaveChanges}
              >
                Guardar Cambios
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

interface SectionHeaderProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  backgroundColor: string;
  progress: number;

}

const SectionHeader: React.FC<SectionHeaderProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  isCollapsed, 
  onToggleCollapse,
  backgroundColor,
  progress

}) => {
  const darkenColor = (color: string, amount: number): string => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
  };

  const darkerColor = darkenColor(backgroundColor, 30); // 30 es la cantidad de oscurecimiento, ajusta según sea necesario

  
  return(
  <div style={{ backgroundColor }} className="text-white px-4 py-2">
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center">
        <button onClick={onToggleCollapse} className="mr-2">
          {isCollapsed ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
        </button>
        <h3 className="text-sm font-bold">{title}</h3>
      </div>
     

      <div className="relative">
      <select
            value={sectionType}
            onChange={(e) => onChangeSectionType(e.target.value as 'GESTIÓN' | 'PERMISOLOGÍA')}
            className="text-white px-2 py-1 rounded appearance-none pr-8 text-sm"
            style={{ backgroundColor: darkerColor }}
          >
            <option value="GESTIÓN">GESTIÓN</option>
            <option value="PERMISOLOGÍA">PERMISOLOGÍA</option>
          </select>
        <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white" size={14} />
      </div>
    </div>
    {!isCollapsed && (
      <div className="flex items-center">
        <span className="text-sm mr-2">
          {sectionType === "PERMISOLOGÍA" ? "Organismo de Tramitación:" : "Gestión con:"}
        </span>
        <input
          type="text"
          value={processingBody}
          onChange={(e) => onChangeProcessingBody(e.target.value)}
          className=" text-white px-2 py-1 text-white rounded text-sm flex-grow"
          style={{ backgroundColor: darkerColor }}

          placeholder={sectionType === "PERMISOLOGÍA" ? "Ingrese organismo" : "Ingrese gestor"}
        />
       {  /*<div className="flex items-center ml-10">
            <div className="mr-4 text-sm "><b>Progreso: {progress.toFixed(0)}%</b></div>
            <div className="w-32 bg-white rounded-full h-2.5">
              <div
                className="bg-green-600 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>*/}
      </div>
    )}
  </div>
);
};

interface ProjectSectionProps {
  title: string;
  sectionType: 'GESTIÓN' | 'PERMISOLOGÍA';
  processingBody: string;
  tasks: Task[];
  onChangeSectionType: (newType: 'GESTIÓN' | 'PERMISOLOGÍA') => void;
  onChangeProcessingBody: (newBody: string) => void;
  onChangeTask: (taskIndex: number, updatedTask: Task) => void;
  isCollapsed: boolean;
  subprocessColor: string;
  onToggleCollapse: () => void;
  
}

const ProjectSection: React.FC<ProjectSectionProps> = ({ 
  title, 
  sectionType, 
  processingBody, 
  tasks, 
  onChangeSectionType, 
  onChangeProcessingBody, 
  onChangeTask, 
  isCollapsed, 
  subprocessColor,
  onToggleCollapse 
}) =>{
  
  const [progress, setProgress] = useState(0);
  const [shouldLoadClosedStatus, setShouldLoadClosedStatus] = useState(true);
  const [cachedClosedStatus, setCachedClosedStatus] = useState<{[key: number]: boolean}>({});
  const [isLoading, setIsLoading] = useState(true);

  

 


/*
  const calculateProgress = useMemo(() => {
    return () => {
      const totalTasks = tasks.length;
      const completedTasks = tasks.filter(task => task.isClosed).length;
      return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
    };
  }, [tasks]);

    const debouncedUpdateProgress = useCallback(
    debounce((newProgress: number) => {
      setProgress(newProgress);
      updateProgressInDatabase(newProgress);
    }, 300),
    []
  );

  useEffect(() => {
    const newProgress = calculateProgress();
    debouncedUpdateProgress(newProgress);
  }, [calculateProgress, debouncedUpdateProgress]);



*/
// Llamada para obtener el estado de las tareas cerradas en una sola solicitud

 
  /*
  const updateProgressInDatabase = async (newProgress: number) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_agrupador_progress.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agrupadorId: title,
          progress: Math.round(newProgress * 100) / 100
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error('Error updating agrupador progress:', error);
      // Aquí podrías mostrar un mensaje de error al usuario si lo deseas
    }
  };
*/

/*
  useEffect(() => {
    const newProgress = calculateProgress();
    debouncedUpdateProgress(newProgress);
  
  }, [calculateProgress, debouncedUpdateProgress]);

*/



  const handleTaskChange = (taskIndex: number, updatedTask: Task) => {
    onChangeTask(taskIndex, updatedTask);
   //const newProgress = calculateProgress();
   // setProgress(newProgress);
    //debouncedUpdateProgress(newProgress);
  };
 
/*
  const calculateSemaphoreStatus = (task: Task, allTasks: Task[]): SemaphoreStatus => {
    const chileTimeZone = 'America/Santiago';
    const now = new Date(new Intl.DateTimeFormat('en-US', { 
      timeZone: chileTimeZone,
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit'
    }).format(new Date()));
  
    const startDate = new Date(task.start);
    const endDate = new Date(task.end);
  
    // Verificar si alguna tarea predecesora está abierta
    const hasPredecessorOpen = task.dependsOn.some(dep => {
   
      const predecessorTask = allTasks.find(t => t.id === dep.taskId);
      return predecessorTask && !predecessorTask.isClosed;
    });
  
    if (hasPredecessorOpen) {
      return SemaphoreStatus.Gray; // Tarea no puede iniciar si una predecesora está abierta
    }
  
    if (task.isClosed) {
      return SemaphoreStatus.Green; // Tarea cerrada
    }
  
    if (now > endDate) {
      // Actualizar la fecha de fin si la tarea está retrasada
      task.end = now.toISOString().split('T')[0];
      return SemaphoreStatus.Red; // Vencida
    } else if (now >= startDate) {
      const totalDuration = endDate.getTime() - startDate.getTime();
      const remainingTime = endDate.getTime() - now.getTime();
      
      if (remainingTime <= 2 * 24 * 60 * 60 * 1000) { // 2 días o menos
        return SemaphoreStatus.Orange; // Casi por vencer
      } else if (remainingTime <= 5 * 24 * 60 * 60 * 1000) { // 5 días o menos
        return SemaphoreStatus.Green; // Por vencer
      } else {
        return SemaphoreStatus.Green; // Iniciada (a tiempo)
      }
    } else {
      return SemaphoreStatus.Gray; // No iniciada, pero dentro del plazo
    }
  };

  const updateTaskStatus = useCallback((task: Task): Task => {
    const updatedTask = { ...task };
    const newStatus = calculateSemaphoreStatus(updatedTask, tasks);
    
    if (updatedTask.semaphoreStatus !== newStatus) {
      updatedTask.semaphoreStatus = newStatus;
    }
    
    // Actualizar la fecha de fin si la tarea está retrasada
    const now = new Date();
    const endDate = new Date(updatedTask.end);
    if (now > endDate && !updatedTask.isClosed) {
      updatedTask.end = now.toISOString().split('T')[0];
    }
    
    return updatedTask;
  }, [tasks, calculateSemaphoreStatus]);*/
/*
  useEffect(() => {
    const updatedTasks = tasks.map(updateTaskStatus);
    if (JSON.stringify(updatedTasks) !== JSON.stringify(tasks)) {
      updatedTasks.forEach((task, index) => {
        if (JSON.stringify(task) !== JSON.stringify(tasks[index])) {
          onChangeTask(index, task);
        }
      });
    }
  }, [tasks, updateTaskStatus, onChangeTask]);*/

const darkenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
};

const darkerColor = darkenColor(subprocessColor, 20);


const lightenColor = (color: string, amount: number): string => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => 
    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
  );
};

// Uso
const lighterColor = lightenColor(subprocessColor, 115);
// Filtrar solo las tareas habilitadas
const enabledTasks = tasks.filter(task => task.enabled);

return (
  <div className="mb-6 shadow-lg">
    <SectionHeader
      title={title}
      sectionType={sectionType}
      processingBody={processingBody}
      onChangeSectionType={onChangeSectionType}
      onChangeProcessingBody={onChangeProcessingBody}
      isCollapsed={isCollapsed}
      onToggleCollapse={onToggleCollapse}
      backgroundColor={subprocessColor}
      progress={progress}
    />
    <SmoothCollapse isOpen={!isCollapsed}>
      {enabledTasks.length > 0 ? (
        <div className="overflow-x-auto overflow-y-hidden bg-white">
          <table className="w-full">
            <thead>
              <tr style={{ backgroundColor: lighterColor }}>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">ID</th>
                <th className="px-4 py-2 text-left text-xs font-medium">Tarea</th>
                <th className="px-4 py-2 text-left text-xs font-medium">Responsable</th>
                <th className="px-4 py-2 text-left text-xs font-medium">Inicio</th>
                <th className="px-4 py-2 text-left text-xs font-medium">Término</th>
                <th className="px-4 py-2 text-center text-xs font-medium">Dependencias</th>
                <th className="px-4 py-2 text-center text-xs font-medium">Seguimiento</th>
                <th className="px-4 py-2 text-center text-xs font-medium">Estado</th>
              </tr>
            </thead>
            <tbody>
              {enabledTasks.map((task, index) => (
                <TaskRow
                  key={task.id}
                  index={index}
                  task={task}
                  onChangeTask={(updatedTask) => onChangeTask(index, updatedTask)}
                  rowColor={index % 2 === 0 ? `${subprocessColor}10` : 'white'} 
                  inputColor={darkerColor}
                  allTasks={enabledTasks}
                  isLoading={isLoading} // Pasar el estado isLoading como prop
                  subprocessColor={subprocessColor} // Añade esta línea

                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-4 text-center text-gray-500">
          No hay tareas habilitadas en este agrupador.
        </div>
      )}
    </SmoothCollapse>
  </div>
);
};

enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}

interface Process {
  id: number;
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[]; // Añadimos esta propiedad
  plantilla: string; // Nombre de la plantilla asociada al proceso

}


interface PlantillaDB {
  id: number;
  nombre: string;
  descripcion: string | null;
  contenido: string; // This will be a JSON string
  tipo: string;
  fecha_creacion: string;
  fecha_modificacion: string;
}

interface Plantilla {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}


interface Props extends Partial<CommonComponentProps> {
  selectedProcess: string;
  setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
  processes: Process[];
}

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  departamento: string;
  is_active: boolean;
}


interface Subproceso {
  id: number;
  nombre: string;
  agrupadores: Agrupador[];
  subproceso: string;
  color?: string;
}

interface Agrupador {
  nombre: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Tarea[];
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
}

interface Tarea {
  id: number;
  nombre: string;
  responsable: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  descriptor: string;
  organismo: string;
  duracion: number;
  dependencia: string | number | null;
  enabled: boolean;
  isClosed: boolean;
}

interface ProcessData {
  nombre: string;
  proceso: string;
  subprocesos: Subproceso[];
}

interface TasksState {
  name: string;
  groups: Group[];
}




const EngineeringProjectTimeline: React.FC<Props> = ({ 
  selectedProcess,
  setSelectedProcess,
  processes,
  navigateToSubComponent,
  ...props
}) => {

const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
const [isProcessLoaded, setIsProcessLoaded] = useState(false);
const [plantillaDB, setPlantillaDB] = useState<PlantillaDB | null>(null);
const [processedPlantilla, setProcessedPlantilla] = useState<any>(null);
const [projectSections, setProjectSections] = useState<ProjectSectionProps[]>([]);
const [allCollapsed, setAllCollapsed] = useState<boolean>(false);
const [hasPlantilla, setHasPlantilla] = useState<boolean>(true);
const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
const [isLoading, setIsLoading] = useState(false);
const [subprocessColors, setSubprocessColors] = useState<{[key: string]: string}>({});

    const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
  const previousProcessRef = useRef<string | null>(null);

  const [initialUpdateDone, setInitialUpdateDone] = useState(false);




useLayoutEffect(() => {
  // Esto se ejecutará sincrónicamente después de todas las mutaciones del DOM
}, [projectSections]);

// Actualizar la función calculateSemaphoreStatus
const calculateSemaphoreStatus = (
  start: string, 
  end: string, 
  taskId: number, 
  dependencia: string | number | null, 
  isClosed: boolean,
  allTasks?: Task[]
): SemaphoreStatus => {
  const chileTimeZone = 'America/Santiago';

  // Función auxiliar para verificar si las dependencias están cerradas
  const areDependenciesClosed = (): boolean => {
    if (!dependencia || !allTasks) return true;
    
    const dependencies = typeof dependencia === 'string' 
      ? dependencia.split(',').map(Number)
      : [Number(dependencia)];
    
    return dependencies.every(depId => {
      const depTask = allTasks.find(t => t.id === depId);
      return depTask && depTask.isClosed;
    });
  };

  // Si la tarea está cerrada, su estado es verde
  if (isClosed) {
    return SemaphoreStatus.Green;
  }

  // Si las dependencias no están cerradas, la tarea no puede iniciar
  if (!areDependenciesClosed()) {
    return SemaphoreStatus.Gray;
  }
  
    // Si la tarea está cerrada, su estado es verde
    if (isClosed) {
      return SemaphoreStatus.Green;
    }
  
    // Obtener la fecha actual en el huso horario de Chile
    const now = new Date(new Intl.DateTimeFormat('en-US', { 
      timeZone: chileTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date()));
  
    // Convertir las fechas de inicio y fin al huso horario de Chile
    const startDate = new Date(new Intl.DateTimeFormat('en-US', {
      timeZone: chileTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date(start)));
  
    const endDate = new Date(new Intl.DateTimeFormat('en-US', {
      timeZone: chileTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date(end)));
  
    const totalDuration = endDate.getTime() - startDate.getTime();
    const elapsedTime = now.getTime() - startDate.getTime();
    const remainingTime = endDate.getTime() - now.getTime();
  
    // Convertir milisegundos a días
    const remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  
    if (now > endDate) {
      return SemaphoreStatus.Red; // Vencida
    } else if (remainingTime <= 2 * 24 * 60 * 60 * 1000) { // 2 días o menos
      return SemaphoreStatus.Orange; // Casi por vencer
    } else if (remainingTime <= 5 * 24 * 60 * 60 * 1000) { // 5 días o menos
      return SemaphoreStatus.Yellow; // Por vencer
    } else if (now >= startDate) {
      return SemaphoreStatus.Green; // Iniciada (a tiempo)
    } else {
      return SemaphoreStatus.Gray; // No iniciada, pero dentro del plazo
    }
  };

const calculateDaysDelayed = useCallback((endDate: string, startDate: string): number => {
  const end = new Date(endDate);
  const start = new Date(startDate);
  const diffTime = end.getTime() - start.getTime();
  return Math.max(0, Math.ceil(diffTime / (1000 * 3600 * 24)));
}, []);



const resetSubprocessState = () => {
  setActiveSubprocess(0);
  setCurrentAgrupadores([]);
  localStorage.removeItem('selectedSubprocess');
};

const loadProcessData = async (processName: string) => {
  setIsLoading(true);
  
  if (processName === "") {
    resetState();
    setIsLoading(false);
    return;
  }

  // Check if the process has actually changed
  if (processName !== previousProcessRef.current) {
    if (previousProcessRef.current !== null) {
      // Reset subprocess state when switching between processes
      resetSubprocessState();
    }
  } else {
    setIsLoading(false);
    return; // Exit early if the process hasn't changed
  }

  const selectedProcessObj = processes.find(p => p.nombreProceso === processName);
  if (selectedProcessObj) {
  //  alert(`El proceso seleccionado ha cambiado a: ${selectedProcessObj.nombreProceso}`);

    try {
      const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?codigo=${selectedProcessObj.codigo}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: ProcessData = await response.json();
      console.log('Datos recibidos:', JSON.stringify(data, null, 2));

      // Sección 1: Información básica del proceso
      setSelectedNombreProyecto(data.nombre);
      setSelectedCodigoProyecto(data.proceso);

      // Sección 2: Subprocesos
      const fetchedSubprocesos: Subproceso[] = data.subprocesos.map((sp: any) => ({
        id: sp.id,
        nombre: sp.nombre,
        subproceso: sp.nombre,
        color: sp.color || getDefaultColor(sp.id),
        agrupadores: (sp.agrupadores || []).filter((agr: Agrupador) => agr.enabled)
      })).filter(sp => sp.agrupadores.length > 0);
      setSubprocesos(fetchedSubprocesos);

      // Sección 3: Agrupadores (todos)
      const allAgrupadores: Agrupador[] = data.subprocesos.flatMap((subproceso: Subproceso) =>
        subproceso.agrupadores.map((agrupador: Agrupador) => ({
          ...agrupador,
          subproceso: subproceso.nombre
        }))
      );
      setAllAgrupadores(allAgrupadores);

      // Sección 4: Filtrado de subprocesos
      setFilteredSubprocesos(fetchedSubprocesos);


      // Sección 5: Colores de subprocesos
      const newSubprocessColors: {[key: string]: string} = {};
      fetchedSubprocesos.forEach(sp => {
        newSubprocessColors[sp.subproceso] = sp.color || "";
      });
      setSubprocessColors(newSubprocessColors);

      setIsProcessLoaded(true);
      setHasPlantilla(fetchedSubprocesos.length > 0);
      previousProcessRef.current = processName;
      // No cargaremos los ProjectSections aquí, lo haremos en un efecto separado
    } catch (error) {
      console.error('Error loading process data:', error);
      setHasPlantilla(false);
      setIsProcessLoaded(false);
    } finally {
      setIsLoading(false);
    }
  }
};



const adjustDependentTasks = useCallback((tasks: Task[], taskId: number, daysToAdjust: number): Task[] => {
  const updatedTasks = [...tasks];
  const adjustTask = (id: number) => {
    const taskIndex = updatedTasks.findIndex(t => t.id === id);
    if (taskIndex === -1) return;

    const task = updatedTasks[taskIndex];
    const newStartDate = new Date(task.start);
    newStartDate.setDate(newStartDate.getDate() + daysToAdjust);
    const newEndDate = new Date(task.end);
    newEndDate.setDate(newEndDate.getDate() + daysToAdjust);

    updatedTasks[taskIndex] = {
      ...task,
      start: newStartDate.toISOString().split('T')[0],
      end: newEndDate.toISOString().split('T')[0]
    };

    // Ajustar tareas dependientes de esta tarea
    updatedTasks.forEach(t => {
      if (t.dependencia === task.id) {
        adjustTask(t.id);
      }
    });
  };

  adjustTask(taskId);
  return updatedTasks;
}, []);








const updateDependentTasks = useCallback((tasks: Task[]): Task[] => {
  const updatedTasks = [...tasks];
  tasks.forEach(task => {
    if (task.dependencia !== null) {
      const parentTask = updatedTasks.find(t => t.id === task.dependencia);
      if (parentTask) {
        const parentEndDate = new Date(parentTask.end);
        const taskStartDate = new Date(task.start);
        if (parentEndDate > taskStartDate) {
          const daysToAdjust = Math.ceil((parentEndDate.getTime() - taskStartDate.getTime()) / (1000 * 3600 * 24));
          adjustDependentTasks(updatedTasks, task.id, daysToAdjust);
        } else if (parentTask.isClosed && parentEndDate < taskStartDate) {
          const daysToAdjust = Math.ceil((taskStartDate.getTime() - parentEndDate.getTime()) / (1000 * 3600 * 24));
          adjustDependentTasks(updatedTasks, task.id, -daysToAdjust);
        }
      }
    }
  });
  return updatedTasks;
}, [adjustDependentTasks]);

// Añade este efecto para cargar los datos del primer subproceso
useEffect(() => {
  if (isProcessLoaded && filteredSubprocesos.length > 0) {
    // Intentamos obtener el subproceso guardado en localStorage
    const savedSubprocess = localStorage.getItem('selectedSubprocess');
    
    if (savedSubprocess) {
      // Buscar si el subproceso guardado existe en la lista
      const subprocessIndex = filteredSubprocesos.findIndex(sp => sp.nombre === savedSubprocess);
      if (subprocessIndex !== -1) {
        // Si existe en la lista, lo cargamos
        loadSubprocessData(savedSubprocess, filteredSubprocesos[subprocessIndex].color || getDefaultColor(filteredSubprocesos[subprocessIndex].id));
        setActiveSubprocess(subprocessIndex);
      } else {
        // Si no está en la lista, cargar el primer subproceso
        const firstSubprocess = filteredSubprocesos[0];
        loadSubprocessData(firstSubprocess.nombre, firstSubprocess.color || getDefaultColor(firstSubprocess.id));
        setActiveSubprocess(0);
      }
    } else {
      // Si no hay subproceso guardado en localStorage, cargar el primero de la lista
      const firstSubprocess = filteredSubprocesos[0];
      loadSubprocessData(firstSubprocess.nombre, firstSubprocess.color || getDefaultColor(firstSubprocess.id));
      setActiveSubprocess(0);
    }
  }
}, [isProcessLoaded, filteredSubprocesos]);

const loadSubprocessData = useCallback((subprocessName: string, subprocessColor: string) => {
  const subprocessAgrupadores = allAgrupadores.filter(ag => ag.subproceso === subprocessName);
  
  const projectSections: ProjectSectionProps[] = subprocessAgrupadores.map((agrupador: Agrupador): ProjectSectionProps => {
    let tasks = agrupador.tareas.map((tarea: Tarea): Task => ({
      id: tarea.id,
      name: tarea.nombre,
      description: tarea.nombre,
      responsible: tarea.responsable,
      progress: tarea.progreso,
      start: tarea.fecha_inicio,
      end: tarea.fecha_termino,
      descriptor: tarea.descriptor as 'GESTIÓN' | 'PERMISOLOGÍA',
      organismo: tarea.organismo,
      dependsOn: (tarea.dependencia && typeof tarea.dependencia === 'string') 
        ? tarea.dependencia.split(',').map(dep => ({ groupId: 0, taskId: Number(dep) }))
        : (tarea.dependencia 
          ? [{ groupId: 0, taskId: Number(tarea.dependencia) }] 
          : []),
      duracion: tarea.duracion,
      enabled: tarea.enabled,
      followUp: false,
      status: TaskStatus.None,
      followUpDate: '',
      semaphoreStatus: SemaphoreStatus.Gray, // Inicializamos en gris, se actualizará después
      dependencia: tarea.dependencia,
      isClosed: tarea.isClosed, // Convert to boolean
      delayInDays: 0
    }));
   // alert(JSON.stringify(tasks))
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const updateDependentTasks = (taskIndex: number, delay: number) => {
      for (let i = taskIndex + 1; i < tasks.length; i++) {
        const task = tasks[i];
        const dependencies = typeof task.dependencia === 'string' 
          ? task.dependencia.split(',').map(Number)
          : task.dependencia ? [Number(task.dependencia)] : [];
    
        if (dependencies.includes(taskIndex + 1)) {
          const newStartDate = new Date(task.start);
          newStartDate.setDate(newStartDate.getDate() + delay);
          const newEndDate = new Date(task.end);
          newEndDate.setDate(newEndDate.getDate() + delay);
    
          const updatedTask = {
            ...task,
            start: newStartDate.toISOString().split('T')[0],
            end: newEndDate.toISOString().split('T')[0],
          };

          // Calcular el estado del semáforo con la nueva función
          const semaphoreStatus = calculateSemaphoreStatus(
            updatedTask.start,
            updatedTask.end,
            updatedTask.id,
            updatedTask.dependencia,
            updatedTask.isClosed,
            tasks
          );
    
          tasks[i] = {
            ...updatedTask,
            semaphoreStatus: semaphoreStatus
          };
    
          console.log(`Actualizando fechas para la tarea dependiente ${task.description} (Posición: ${i + 1}):`);
          console.log(`  Nueva fecha de inicio: ${tasks[i].start}`);
          console.log(`  Nueva fecha de fin: ${tasks[i].end}`);
          console.log(`  Estado del semáforo: ${semaphoreStatus}`);
    
          updateDependentTasks(i, delay);
        }
      }
    };
    
    tasks.forEach((task, index) => {
      const [year, month, day] = task.end.split('-').map(Number);
      const taskEndDate = new Date(year, month - 1, day);  
      taskEndDate.setHours(0, 0, 0, 0);
      
      const semaphoreStatus = calculateSemaphoreStatus(
        task.start,
        task.end,
        task.id,
        task.dependencia,
        task.isClosed,
        tasks
      );

      if (taskEndDate < today && !task.isClosed) {
        console.log(`Tarea retrasada detectada en loadSubprocessData:`);
        console.log(`Agrupador: ${agrupador.nombre}`);
        console.log(`Tarea: ${task.description} (Posición: ${index + 1})`);
    
        const delayInDays = task.delayInDays || Math.ceil((today.getTime() - taskEndDate.getTime()) / (1000 * 3600 * 24));
        
        tasks[index] = {
          ...task,
          delayInDays: delayInDays,
          semaphoreStatus: semaphoreStatus
        };
    
        console.log(`Retraso: ${delayInDays} día(s)`);
        console.log(`Estado del semáforo: ${semaphoreStatus}`);
    
        updateDependentTasks(index, delayInDays);
      } else {
        tasks[index] = {
          ...task,
          semaphoreStatus: semaphoreStatus
        };
      }
    });

    return {
      title: agrupador.nombre,
      sectionType: agrupador.descriptor.toUpperCase() as 'GESTIÓN' | 'PERMISOLOGÍA',
      processingBody: agrupador.organismo,
      isCollapsed: false,
      tasks: tasks,
      onChangeSectionType: () => {},
      onChangeProcessingBody: () => {},
      onChangeTask: () => {},
      onToggleCollapse: () => {},
      subprocessColor: subprocessColor
    };
  });
  
  setProjectSections(projectSections);
}, [allAgrupadores, calculateSemaphoreStatus]);






// Modifica handleSubprocessChange para usar loadSubprocessData
const handleSubprocessChange = (index: number) => {
  setActiveSubprocess(index);
  const selectedSubprocess = filteredSubprocesos[index];

  if (selectedSubprocess) {
    const subprocesoName = selectedSubprocess.nombre;
    const color = selectedSubprocess.color || getDefaultColor(selectedSubprocess.id);
    localStorage.setItem('selectedSubprocess', subprocesoName);

    loadSubprocessData(subprocesoName, color);
  } else {
    console.error('Subproceso no encontrado');
    setProjectSections([]);
  }
};



const handleChangeSectionType = (sectionIndex: number, newType: 'GESTIÓN' | 'PERMISOLOGÍA') => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    sectionType: newType
  };
  setProjectSections(updatedSections);
};

const handleChangeProcessingBody = (sectionIndex: number, newBody: string) => {
  const updatedSections = [...projectSections];
  updatedSections[sectionIndex] = {
    ...updatedSections[sectionIndex],
    processingBody: newBody
  };
  setProjectSections(updatedSections);
};


const handleChangeTask = useCallback((sectionIndex: number, taskIndex: number, updatedTask: Task) => {
  setProjectSections(prevSections => {
    const newSections = [...prevSections];
    let sectionTasks = [...newSections[sectionIndex].tasks];
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const taskEndDate = new Date(updatedTask.end);
    taskEndDate.setHours(0, 0, 0, 0);

    if (taskEndDate < today && !updatedTask.isClosed) {
      console.log(`Tarea retrasada detectada en handleChangeTask:`);
      console.log(`Sección: ${newSections[sectionIndex].title}`);
      console.log(`Total de tareas en la sección: ${sectionTasks.length}`);
      
      // Usamos el delayInDays existente
      const delayInDays = updatedTask.delayInDays || 0;
      
      // Actualizamos las tareas dependientes
      const { updatedTasks } = handleDependenciesAndDates(sectionTasks, taskIndex);
      sectionTasks = updatedTasks;
    }

    sectionTasks[taskIndex] = updatedTask;
    newSections[sectionIndex] = {
      ...newSections[sectionIndex],
      tasks: sectionTasks
    };
    return newSections;
  });
}, []);


const adjustTaskDates = useCallback((tasks: Task[]): Task[] => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Asegurarnos de que sea la medianoche en la zona horaria local
  const adjustedTasks = [...tasks];

  const adjustTask = (taskPosition: number) => {
    if (taskPosition < 0 || taskPosition >= adjustedTasks.length) return;

    const task = adjustedTasks[taskPosition];
    const taskEndDate = new Date(task.end);

    // Ajustamos la hora de la fecha de la tarea a medianoche
    taskEndDate.setHours(0, 0, 0, 0);

    // Verificamos si la fecha de término de la tarea ya pasó (excluyendo el día de hoy)
    if (taskEndDate < today && !task.isClosed) {
      // Cálculo de los días de retraso basado en la diferencia entre hoy y la fecha de término de la tarea
      const delayInDays = Math.ceil((today.getTime() - taskEndDate.getTime()) / (1000 * 3600 * 24));

      // Calcular la nueva fecha sumando el retraso + 1 día
      const newAdjustedDate = new Date(taskEndDate);
      newAdjustedDate.setDate(newAdjustedDate.getDate() + delayInDays + 1); // Sumar días de retraso + 1

      // Mostrar un alert con la fecha de término original, los días de retraso y la nueva fecha ajustada
   //   alert(`La tarea "${task.description}" (Posición: ${taskPosition + 1}) con fecha de término original ${task.end} tiene ${delayInDays} días de retraso.\nNueva fecha ajustada: ${newAdjustedDate.toISOString().split('T')[0]}`);

      // Solo actualizamos si el retraso ha cambiado
      if (task.delayInDays !== delayInDays) {
        adjustedTasks[taskPosition] = {
          ...task,
          delayInDays: delayInDays, // Actualizar días de retraso para esta tarea
          semaphoreStatus: calculateSemaphoreStatus(
            task.start,
            task.end,
            task.id,
            task.dependencia,
            task.isClosed,
            adjustedTasks
          )
        };

        // Ajustar las tareas dependientes
        adjustedTasks.forEach((dependentTask, dependentTaskPosition) => {
          // Las dependencias están basadas en las posiciones, no en los IDs
          const dependencies = typeof dependentTask.dependencia === 'string'
            ? dependentTask.dependencia.split(',').map(Number)
            : dependentTask.dependencia ? [Number(dependentTask.dependencia)] : [];

          // Verificar si la tarea dependiente tiene una dependencia directa de la tarea retrasada (basado en la posición)
          if (dependencies.includes(taskPosition + 1) && !dependentTask.isClosed) {
            // Mostrar un alert con la tarea dependiente directa
         //   alert(`La tarea dependiente directa de la tarea en posición ${taskPosition + 1} es "${dependentTask.description}" (Posición: ${dependentTaskPosition + 1}).`);

            // Usar la nueva fecha ajustada como la nueva fecha de inicio para la tarea dependiente
            const newStartDate = new Date(newAdjustedDate); // Aquí usamos la fecha ajustada de la tarea retrasada
            const currentEndDate = new Date(dependentTask.end);

            // Ajustar la nueva fecha de fin de la tarea dependiente en función de su duración
            const duration = currentEndDate.getTime() - new Date(dependentTask.start).getTime();
            const newEndDate = new Date(newStartDate.getTime() + duration);

            // Actualizar la tarea dependiente con las nuevas fechas
            adjustedTasks[dependentTaskPosition] = {
              ...dependentTask,
              start: newStartDate.toISOString().split('T')[0],
              end: newEndDate.toISOString().split('T')[0],
              delayInDays: Math.ceil((newStartDate.getTime() - new Date(dependentTask.start).getTime()) / (1000 * 3600 * 24)),
              semaphoreStatus: calculateSemaphoreStatus(
                newStartDate.toISOString().split('T')[0],
                newEndDate.toISOString().split('T')[0],
                dependentTask.id,
                dependentTask.dependencia,
                dependentTask.isClosed,
                adjustedTasks
              )
            };

            // Recursivamente ajustar las tareas que dependen de esta
            adjustTask(dependentTaskPosition);
          }
        });
      }
    }
  };

  // Identificar las tareas retrasadas y ajustar sus dependientes
  adjustedTasks.forEach((task, index) => {
    if (!task.isClosed) {
      const taskEndDate = new Date(task.end);
      taskEndDate.setHours(0, 0, 0, 0); // Asegurarnos de que sea medianoche en la zona horaria local
      if (taskEndDate < today) {
        adjustTask(index); // Ajustamos la tarea retrasada por posición
      }
    }
  });

  return adjustedTasks;
}, [calculateSemaphoreStatus]);








const updateTasksInDatabase = async (tasks: Task[]) => {
  try {
    const tasksToUpdate = tasks.map(task => ({
      id: task.id,
      start: task.start,
      end: task.end,
      semaphoreStatus: task.semaphoreStatus,
      delayInDays: task.delayInDays || 0
    }));

    const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_multiple_tasks.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tasksToUpdate),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to update tasks');
    }

    const result = await response.json();
    console.log(result.message);
  } catch (error) {
    console.error('Error updating tasks:', error);
  }
};
useEffect(() => {
  
  if (!initialUpdateDone && projectSections.length > 0) {
    const updatedSections = projectSections.map(section => ({
      ...section,
      tasks: adjustTaskDates(section.tasks)
    }));

    
    // Actualizar el estado con las secciones ajustadas
    setProjectSections(updatedSections);

    // Recopilar todas las tareas actualizadas
    const allUpdatedTasks = updatedSections.flatMap(section => section.tasks);

    // Actualizar las tareas en la base de datos
    updateTasksInDatabase(allUpdatedTasks);

    // Marcar que la actualización inicial se ha completado
    setInitialUpdateDone(true);
  }
}, [projectSections, adjustTaskDates, initialUpdateDone]);

  const handleToggleCollapse = (sectionIndex: number) => {
    const updatedSections = [...projectSections];
    updatedSections[sectionIndex].isCollapsed = !updatedSections[sectionIndex].isCollapsed;
    setProjectSections(updatedSections);
  };

  const handleToggleAllCollapse = () => {
    const newCollapsedState = !allCollapsed;
    setAllCollapsed(newCollapsedState);
    const updatedSections = projectSections.map(section => ({
      ...section,
      isCollapsed: newCollapsedState
    }));
    setProjectSections(updatedSections);
  };
  

  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setFilteredSubprocesos([]);
    setAllAgrupadores([]);
    setCurrentAgrupadores([]);
    setIsProcessLoaded(false);
  };



  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    } else {
      resetState();
    }
  }, [selectedProcess]);


  if (!selectedProcess) {
    return <NoProcesoSeleccionadoMessage />;
  }
 



/*

  useEffect(() => {
    setSelectedCodigoProyecto('');
      setSubprocesos([]);
  
  
      const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php?type=type3');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };
    fetchProcesses();
  }, []);
  
  */




  
  // Función auxiliar para generar un color por defecto si no se proporciona uno
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  /*
  const handleProcessChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    alert(selectedValue);
    if (selectedValue === "") {
      // "Asignar proceso" was selected
      setSelectedCodigoProyecto('');
      setSubprocesos([]);
      setIsProcessLoaded(false)
      setProjectSections([]);
    } else {
      setSelectedProcess?.(selectedValue); // Use optional chaining
      const selectedProcess = processes.find(p => p.nombreProceso === selectedValue);
      if (selectedProcess) {
        
        setSelectedCodigoProyecto(selectedProcess.codigo);
        
        // Cargar subprocesos
        fetchSubprocesos(selectedProcess.codigo);
        setIsProcessLoaded(true);

         // Fetch plantilla name
         const plantillaNombre = await fetchPlantillaNombre(selectedProcess.codigo);
         if (plantillaNombre) {
          await fetchPlantilla(plantillaNombre); 
          setIsProcessLoaded(true);


         } else {
           console.error('No se pudo obtener el nombre de la plantilla');
           setIsProcessLoaded(false);
         }

      }
      
    }
  };
*/



const handleNavigateToEditPlantillas = () => {
  if (navigateToSubComponent) {
    navigateToSubComponent('ADMINISTRACIÓN', 'Mis plantillas');
  } else {
    console.error('navigateToSubComponent is not defined');
  }
};
const sectionVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      type: "spring",
      stiffness: 100,
      damping: 15,
      mass: 0.3
    }
  },
  exit: { 
    opacity: 0, 
    y: -10, 
    transition: { 
      duration: 0.2
    }
  }
};

// 1. Animación de deslizamiento lateral
const slideVariants = {
  hidden: { x: '-100%' },
  visible: { 
    x: 0,
    transition: { type: 'spring', stiffness: 120, damping: 20 }
  },
  exit: { x: '100%' }
};

// 2. Animación de fundido con escala
const fadeScaleVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { duration: 0.3 }
  },
  exit: { opacity: 0, scale: 1.2 }
};

// 3. Animación de aparición desde el centro
const expandVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { type: 'spring', stiffness: 200, damping: 20 }
  },
  exit: { opacity: 0, scale: 0 }
};

// 4. Animación de rotación y escala
const rotateScaleVariants = {
  hidden: { rotate: -180, scale: 0 },
  visible: { 
    rotate: 0, 
    scale: 1,
    transition: { type: 'spring', stiffness: 150, damping: 20 }
  },
  exit: { rotate: 180, scale: 0 }
};

// 5. Animación de rebote
const bounceVariants = {
  hidden: { y: '-100%', opacity: 0 },
  visible: { 
    y: 0,
    opacity: 1,
    transition: { 
      type: 'spring',
      stiffness: 300,
      damping: 25,
      mass: 0.5
    }
  },
  exit: { y: '100%', opacity: 0 }
};
return (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="w-full bg-white rounded-lg p-3 mx-auto"
  >
    {isProcessLoaded && (
      <AnimatePresence mode="wait">
        <motion.div
          key="plantilla-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="sticky top-0 z-10 bg-white">
            <div className="p-2 mx-auto">
              {filteredSubprocesos.length > 0 ? (
                <>
                  {/* Render SubprocessTabs si hay subprocesos */}
                  <span className="font-bold">Subprocesos:</span>
                  <div className="flex justify-between items-center border-b border-none py-2">
                    <div className="flex flex-wrap items-center flex-grow">
                      {filteredSubprocesos.map((subprocess, index) => (
                        <SubprocessTab
                          key={index}
                          label={subprocess.nombre} 
                          isActive={activeSubprocess === index}
                          onClick={() => handleSubprocessChange(index)}
                          color={subprocess.color || ''}
                          index={index}
                          totalTabs={filteredSubprocesos.length}
                          activeIndex={activeSubprocess}
                        />
                      ))}
                    </div>
                    <motion.button
                      onClick={handleToggleAllCollapse}
                      className="ml-4 px-3 py-2 flex items-center text-sm border border-black border-2 transition-colors duration-200 h-[38px]"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {allCollapsed ? <Maximize size={20} className="mr-2" /> : <Minimize size={20} className="mr-2" />}
                      <span className="whitespace-nowrap">
                        {allCollapsed ? "Expandir Todo" : "Colapsar Todo"}
                      </span>
                    </motion.button>
                  </div>

                  {/* Render ProjectSections si hay agrupadores */}
                  {projectSections.length > 0 ? (
                    <motion.div
                      className="pr-2 pl-2 mx-auto"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.2 }}
                    >
                      <AnimatePresence mode="wait">
                        {projectSections.map((section, index) => (
                          <motion.div
                            key={section.title}
                            variants={sectionVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            transition={{ delay: index * 0.03 }}
                            layout
                          >
                            <ProjectSection
                              title={section.title}
                              sectionType={section.sectionType as 'GESTIÓN' | 'PERMISOLOGÍA'}
                              processingBody={section.processingBody}
                              tasks={section.tasks}
                              onChangeSectionType={(newType: 'GESTIÓN' | 'PERMISOLOGÍA') => handleChangeSectionType(index, newType)}
                              onChangeProcessingBody={(newBody) => handleChangeProcessingBody(index, newBody)}
                              onChangeTask={(taskIndex, updatedTask) => handleChangeTask(index, taskIndex, updatedTask)}
                              isCollapsed={section.isCollapsed}
                              onToggleCollapse={() => handleToggleCollapse(index)}
                              subprocessColor={section.subprocessColor}
                            />
                          </motion.div>
                        ))}
                      </AnimatePresence>
                    </motion.div>
                  ) : (
                    <div className="flex justify-center items-center w-full">
                      <span>No hay datos disponibles para este proceso</span> {/* Mensaje cuando no hay datos */}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center w-full">
                 <NoAgrupadoresMessage/> {/* Mensaje cuando no hay agrupadores */}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    )}
  </motion.div>
);
};

export default EngineeringProjectTimeline;