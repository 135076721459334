import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Gantt, Task, ViewMode, EventOption, StylingOption, DisplayOption } from 'gantt-task-react-lite';
import "gantt-task-react-lite/dist/index.css";
import { Button } from '../ui/button';
import { ZoomIn, ZoomOut, Maximize, Minimize, Layers, ChevronRight, ChevronDown, Shrink, Expand, LucideZoomIn, LucideZoomOut } from 'lucide-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
const ProgressSlider: React.FC<{
  value: number;
  onChange: (value: number) => void;
  style?: React.CSSProperties;
  ariaLabel?: string;
}> = ({ value, onChange, style, ariaLabel }) => {
  return (
    <Slider
      value={value}
      min={0}
      max={100}
      step={1}
      railStyle={{
        height: 8,
        backgroundColor: '#e0e0e0',
      }}
      handleStyle={{
        width: 16,
        height: 16,
        marginLeft: -8,
        marginTop: -4,
        backgroundColor: '#0044ff',
        border: 'none',
      }}
      trackStyle={{
        backgroundColor: '#0044ff',
      }}
      style={{
        width: '100%',
        ...style,
      }}
    />
  );
};



interface TaskInput {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string; // Añadido para el color de la tarea
}

interface Group {
  agrupador: string;
  tasks: TaskInput[];
  subprocess: string; // Añadido para el nombre del subproceso
  color?: string; // Añadido para el color del grupo/subproceso
}

interface TasksState {
  name: string;
  groups: Group[];
}
interface GanttChartViewProps {
  tasks: TasksState;
  colors?: { [key: string]: string };  // Nuevo prop para colores personalizados
}

const CustomHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, rowWidth, fontFamily, fontSize }) => {
  return (
    <div
      className="gantt-task-list-header"
      style={{
        height: headerHeight,
        fontFamily: fontFamily,
        fontSize: fontSize,
        display: 'flex',
        borderBottom: '2px solid #e0e0e0',
        fontWeight: 'bold',
        alignItems: 'flex-end', // Alinear el contenido hacia abajo
        paddingBottom: '5px',  // Espacio adicional debajo
      }}
    >
     <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ width: '50px', textAlign: 'center' }}>#</th>
            <th style={{ width: rowWidth, textAlign: 'left', paddingLeft: '5px' }}>Agrupador/Tarea</th>
            <th style={{ width: '120px', textAlign: 'left' }}>Desde</th>
            <th style={{ width: '120px', textAlign: 'left' }}>Hasta</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};


interface DateSetup {
  dates: Date[];
  // Añade otras propiedades si son necesarias
}
const CustomTaskListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  fontFamily,
  fontSize,
  locale,
  tasks,
  selectedTaskId,
  setSelectedTask,
  onExpanderClick,
}) => {
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);
  const [selectedTaskProgress, setSelectedTaskProgress] = useState(0);


  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Función para generar colores para los agrupadores
  const getGroupColor = (index: number): string => {
    const colors = ['#FFD700', '#98FB98', '#87CEFA', '#FFA07A', '#DDA0DD'];
    return colors[index % colors.length];
  };

  const darkenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Oscurecer los valores RGB
    r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };


  const lightenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Aumentar los valores RGB para aclarar el color
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };
  
  

  const removeAlpha = (color: string): string => {
    // Si el color tiene 8 caracteres (incluyendo alpha), recortar los últimos 2.
    return color.length === 9 ? color.slice(0, 7) : color;
  };
  
  
  const getTaskBackgroundColor = (task: Task, isHovered: boolean, isSelected: boolean): string => {
    let color;
    if (task.type === 'project') {
      // Keep the background color for group headers (projects)
      color = task.styles?.backgroundColor || getGroupColor(parseInt(task.id.split('-')[1]));
    } else {
      // Default to white for tasks 
      color = '#FFFFFF';
    }
  
    // Only apply hover/selection styles to non-project tasks
    if (task.type !== 'project') {
      if (isSelected) {
        return darkenColor(lightenColor(color, 0.2), 0.1); // Adjust for selection
      } else if (isHovered) {
        return darkenColor(lightenColor(color, 0.3), 0.1); // Adjust for hover
      }
    }
  
    return color;
  };
  let currentGroupIndex = -1;
  let taskIndexInGroup = 0;
  let overallTaskIndex = 0;

  return (
    <div
      className="gantt-task-list-wrapper"
      style={{
        fontFamily,
        fontSize,
      }}
    >
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          {tasks.map((task) => {
            if (task.type === 'project') {
              currentGroupIndex++;
              taskIndexInGroup = 0;
            } else {
              taskIndexInGroup++;
              overallTaskIndex++;
            }

            const isHovered = hoveredTaskId === task.id;
            const isSelected = selectedTaskId === task.id;
            
            return (
              <tr
                key={task.id}
                style={{
                  height: `${rowHeight}px`,
                  backgroundColor: getTaskBackgroundColor(task, isHovered, isSelected),
                  transition: 'background-color 0.3s ease',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedTask(task.id);
                  if (task.type === 'project') {
                    onExpanderClick(task);
                  }
                }}
                onMouseEnter={() => setHoveredTaskId(task.id)}
                onMouseLeave={() => setHoveredTaskId(null)}
              >
                <td style={{ width: '30px', textAlign: 'right', paddingRight: '5px' }}>
                {task.type === 'project' && (
                    <span
                      className="gantt-task-list-expander"
                      style={{ cursor: 'pointer', display: 'inline-block' }}
                    >
                      {task.hideChildren ? <ChevronRight size={16} /> : <ChevronDown size={16} />}
                    </span>
                  )}
                  {task.type === 'task' ? `${overallTaskIndex}` : ''}
                </td>
               
                <td
                  style={{
                    paddingLeft: '5px',
                    paddingRight: '20px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <span style={{ fontWeight: task.type === 'project' ? 'bold' : 'normal' }}>
                    {task.name}
                  </span>
                </td>
                <td style={{ width: '120px' }}>{formatDate(task.start)}</td>
                <td style={{ width: '120px' }}>{formatDate(task.end)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};


const CustomTimelineHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  tickWidth: number;
  startDate: Date;
  endDate: Date;
  dateSetup: DateSetup;
  locale: string;
  headerColor: string;
}> = ({
  headerHeight,
  fontFamily,
  fontSize,
  tickWidth,
  startDate,
  endDate,
  dateSetup,
  locale,
}) => {

  const getWeekNumber = (date: Date): number => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
  };
  
  const getWeekNumberLabel = (date: Date) => {
    const weekNumber = getWeekNumber(date);
    return `${weekNumber}`;
  };

  return (
    <div
      className="gantt-timeline-header"
      style={{
        fontFamily,
        fontSize,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      {/* Semanas */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date, i: number) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              fontWeight: 'bold',
            }}
          >
            {i === 0 || date.getDay() === 1 ? getWeekNumberLabel(date) : ''}
          </div>
        ))}
      </div>
      {/* Días */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              color: date.getDay() === 0 || date.getDay() === 6 ? '#999' : '#000',
            }}
          >
            {date.getDate()}
          </div>
        ))}
      </div>
    </div>
  );
};


const getTodayStyle = () => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date(todayStart);
  todayEnd.setDate(todayEnd.getDate() + 1);

  return {
    backgroundColor: 'rgba(255, 0, 0, 0.3)',
    width: '2px',
    height: '100%',
    position: 'absolute' as 'absolute',
    zIndex: 1,
    left: 'calc(50% - 1px)',
  };
};


const GanttChartView: React.FC<GanttChartViewProps> = ({ tasks, colors = {} }) => {
  const [zoom, setZoom] = useState(100);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Week);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isGrouped, setIsGrouped] = useState(true);

      // Inicializa expandedGroups con todos los grupos expandidos desde el inicio
  const initialExpandedGroups = useMemo(() => {
    return new Set(tasks.groups.map((_, index) => `group-${index}`));
  }, [tasks.groups]);


  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(initialExpandedGroups);
  const [areAllGroupsExpanded, setAreAllGroupsExpanded] = useState(true);

    const ganttRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedTaskId, setSelectedTaskId] = useState<string>('');
    
    const getTaskColor = (task: TaskInput, allTasks: TaskInput[], groupColor: string): string | Object => {
      const today = new Date();
      const start = new Date(task.start);
      const end = new Date(task.end);
      const daysUntilEnd = Math.ceil((end.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  
      // Verificar si todas las tareas predecesoras están completadas
      const allPredecessorsCompleted = task.dependsOn.every(dep => {
        const predecessorTask = allTasks.find(t => t.id === dep.taskId);
        return predecessorTask && predecessorTask.progress === 100;
      });
  
      // Si hay predecesoras incompletas y la tarea aún no ha comenzado, se pinta de gris
      if (!allPredecessorsCompleted && today < start) {
        return '#AEB8C2'; // Gris para tareas con predecesoras incompletas
      }
  
      // Aplicar las condiciones originales para todas las demás situaciones
      if (today > end && task.progress === 100) {
        return groupColor; // Color del subproceso para tareas finalizadas

        // return '#48D2D4'; // Finalizada
      } else if (today < start)  {
        return '#AEB8C2'; // Gris para tareas no iniciadas
      } else if (today > end) {
        return '#EF4444'; // Rojo para tareas vencidas
      } else if (daysUntilEnd <= 2) {
        return '#FB923C'; // Naranja para tareas a punto de vencer (2 días o menos)
      } else if (daysUntilEnd <= 5) {
        return '#FDE047'; // Amarillo para tareas por vencer (5 días o menos)
      } else {
        return '#4ADE80'; // Verde para tareas en progreso
      }
    };

    const getTaskProgress = (groupIndex: number, taskPosition: number): number | null => {
      const group = tasks.groups[groupIndex];  // Obtener el grupo basado en el índice
    
      if (!group) {
        console.error(`No se encontró el grupo con índice ${groupIndex}`);
        return null; // Retorna null si no se encuentra el grupo
      }
    
      // Verificar que la posición sea válida dentro del grupo
      if (taskPosition < 1 || taskPosition > group.tasks.length) {
        console.error(`La posición de la tarea ${taskPosition} está fuera de rango en el grupo ${groupIndex}`);
        return null; // Retorna null si la posición está fuera de rango
      }
    
      const task = group.tasks[taskPosition - 1]; // Las posiciones empiezan en 1, así que restamos 1 para obtener el índice correcto
    
      return task.progress; // Retornar el progreso de la tarea
    };
    

    

    const getDefaultColor = (index: number): string => {
      const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#DDA0DD', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
      return colors[index % colors.length];
    };
    
    const chartData: Task[] = useMemo(() => {
      const today = new Date();
    
      if (isGrouped) {
        return tasks.groups.flatMap((group, groupIndex): Task[] => {
          const groupColor = group.color || getDefaultColor(groupIndex);

          const groupTasks = group.tasks.map((task, taskIndex): Task => {
            const start = new Date(task.start);
            const end = new Date(task.end);
            const isCompletedAndFinished = task.progress === 100 && today > end;
            let taskColor = getTaskColor(task, group.tasks, groupColor); // Pasamos el color del grupo
    
            // Comprobar el progreso de las tareas dependientes
            task.dependsOn.forEach(dep => {
              const progresoDependencia = getTaskProgress(groupIndex, dep.taskId);
              console.log(`Progreso de la tarea ${dep.taskId}:`, progresoDependencia);
    
              // Si alguna dependencia no está completa (progreso < 100), se mantiene gris
              if (progresoDependencia !== null && progresoDependencia !== undefined && progresoDependencia < 100) {
                taskColor = '#AEB8C2'; // Cambiar el color de la tarea a gris
              }
            });
    
            return {
              id: `${groupIndex}-${taskIndex + 1}`,
              name: `${task.name}`, 
              start,
              end,
              progress: task.progress,
              type: 'task',
              project: `group-${groupIndex}`,
              dependencies: task.dependsOn.map(dep => `${groupIndex}-${dep.taskId}`),
              styles: typeof taskColor === 'string'
                ? {
                  progressColor: taskColor,
                  progressSelectedColor: taskColor,
                  backgroundColor: `${taskColor}66`,
                  backgroundSelectedColor: `${taskColor}aa`,
                }
                : {
                  ...taskColor,
                  progressColor: 'transparent',
                  progressSelectedColor: 'transparent',
                },
            };
          });
    
          const groupStart = new Date(Math.min(...groupTasks.map((task) => task.start.getTime())));
          const groupEnd = new Date(Math.max(...groupTasks.map((task) => task.end.getTime())));
          const groupProgress = groupTasks.reduce((sum, task) => sum + task.progress, 0) / groupTasks.length;
    
          const darkenColor = (color: string, amount: number = 0.2): string => {
            return color
              .replace(/^#/, '')
              .replace(/.{2}/g, (c) =>
                Math.max(0, Math.min(255, parseInt(c, 16) - Math.round(255 * amount)))
                  .toString(16)
                  .padStart(2, '0')
              );
          };
    
          const isExpanded = expandedGroups.has(`group-${groupIndex}`);
          const baseColor = group.color || "#000"; 
          const darkColor = `#${darkenColor(baseColor, 0.2)}`;
    
          const groupTask: Task = {
            id: `group-${groupIndex}`,
            name: group.agrupador,
            start: groupStart,
            end: groupEnd,
            progress: groupProgress,
            type: 'project',
            hideChildren: !isExpanded,
            isDisabled: false,
            project: undefined,
            styles: {
              progressColor: baseColor,
              progressSelectedColor: darkColor,
              backgroundColor: baseColor,
              backgroundSelectedColor: darkColor,
            },
            dependencies: [],
          };
    
          return isExpanded ? [groupTask, ...groupTasks] : [groupTask];
        });
      } else {
        
        return tasks.groups.flatMap((group, groupIndex) =>
          
          group.tasks.map((task): Task => {
            const start = new Date(task.start);
            const end = new Date(task.end);
            const groupColor = group.color || getDefaultColor(groupIndex);



            let taskColor = getTaskColor(task, group.tasks, groupColor);

    
            // Comprobar el progreso de las dependencias
            task.dependsOn.forEach(dep => {
              const progresoDependencia = getTaskProgress(groupIndex, dep.taskId);
              if (progresoDependencia !== null && progresoDependencia !== undefined && progresoDependencia < 100) {
                taskColor = 'gray'; // Cambiar color a gris si alguna dependencia no está completa
              }
            });
    
            return {
              id: `${groupIndex}-${task.id}`,
              name: task.name,
              start,
              end,
              progress: task.progress,
              type: 'task',
              project: group.agrupador,
              dependencies: task.dependsOn.map(dep => `${groupIndex}-${dep.taskId}`),
              styles: typeof taskColor === 'string'
                ? {
                  progressColor: taskColor,
                  progressSelectedColor: taskColor,
                  backgroundColor: `${taskColor}66`,
                  backgroundSelectedColor: `${taskColor}aa`,
                }
                : {
                  ...taskColor,
                  progressColor: 'transparent',
                  progressSelectedColor: 'transparent',
                },
            };
          })
        );
      }
    }, [tasks, isGrouped, expandedGroups]);
    
    
    
    const handleZoomChange = useCallback((value: number | number[]) => {
      if (Array.isArray(value)) {
        setZoom(value[0]);  // Si es un array, usamos el primer valor
      } else {
        setZoom(value);  // Si es un número, lo usamos directamente
      }
    }, []);
    

    const handleViewModeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
      setViewMode(e.target.value as ViewMode);
    }, []);

    

    const toggleAllGroups = () => {
      if (areAllGroupsExpanded) {
        setExpandedGroups(new Set()); // Contrae todos los grupos
      } else {
        const allGroupIds = tasks.groups.map((_, index) => `group-${index}`);
        setExpandedGroups(new Set(allGroupIds)); // Expande todos los grupos
      }
      setAreAllGroupsExpanded(!areAllGroupsExpanded);
    };
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        containerRef.current?.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    };

    
    const handleTaskSelect = (task: Task) => {
      setSelectedTaskId(task.id);
      
    };

    

    const handleExpanderClick = (task: Task) => {
      if (task.type === 'project') {
        toggleGroup(task.id);
      }
    };


    const toggleGrouping = () => {
      setIsGrouped(prev => !prev);
      setExpandedGroups(new Set());
    };

    const toggleGroup = (groupId: string) => {
      setExpandedGroups(prev => {
        const newSet = new Set(prev);
        if (newSet.has(groupId)) {
          newSet.delete(groupId);
        } else {
          newSet.add(groupId);
        }
        return newSet;
      });
    };

    // We no longer need handleExpanderClick as the expansion is handled by the chevron click

    useEffect(() => {
      const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
      };

      document.addEventListener('fullscreenchange', handleFullScreenChange);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
      };
    }, []);

    
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDragging && ganttRef.current) {
        const dx = e.clientX - dragStart.x;
        const dy = e.clientY - dragStart.y;
        ganttRef.current.scrollLeft -= dx;
        ganttRef.current.scrollTop -= dy;
        setDragStart({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        handleZoomChange(e.deltaY > 0 ? -10 : 10);
      }
    };

    useEffect(() => {
      const ganttElement = ganttRef.current;
      if (ganttElement) {
        ganttElement.addEventListener('wheel', handleWheel, { passive: false });
      }
      return () => {
        if (ganttElement) {
          ganttElement.removeEventListener('wheel', handleWheel);
        }
      };
    }, [handleZoomChange]);

    const formatDate = (date: Date): string => {
      return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const getWeekNumber = (date: Date): number => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    };


    const columnWidth = useMemo(() => {
      return viewMode === ViewMode.Month ? 300 * (zoom / 100) : 65 * (zoom / 100);
  }, [zoom, viewMode]);

    const formatTimeTick = (date: Date): string => {
      const formatted = formatDate(date);
      if (viewMode === ViewMode.Week) {
        const weekNumber = getWeekNumber(date);
        return `S${String(weekNumber).padStart(2, '0')} - ${formatted}`;
      }
      return formatted;
    };

    useEffect(() => {
      const style = document.createElement('style');
      style.textContent = `
        .gantt-today-highlight {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 2px !important;
          background-color: rgba(255, 0, 0, 0.5) !important;
          z-index: 1;
        }
      `;
      document.head.appendChild(style);
    
      return () => {
        document.head.removeChild(style);
      };
    }, []);

    const totalTasks = useMemo(() => {
      return tasks.groups.reduce((total, group) => total + group.tasks.length + 1, 0);
  }, [tasks]);


  const FloatingActionBar = () => (
    <div className="fixed right-4 bottom-0 transform -translate-y-1/2 flex flex-col space-y-2 z-50">
      
    
    </div>
  );



    return (
      <div 
      ref={containerRef} 
      className={`gantt-container flex flex-col bg-white rounded-lg shadow-lg ${
        isFullScreen ? 'fixed inset-0 z-50' : ''
      }`}
    >
      <div className="flex justify-between items-center mb-4 p-4 border-b">
      <div className="flex items-center space-x-4">
  <ZoomOut 
    className="w-6 h-6 cursor-pointer" 
    onClick={() => handleZoomChange(zoom - 10)} 
  />
  <Slider
    value={zoom}
    min={50}
    max={200}
    step={10}
    onChange={handleZoomChange}
    className="w-40"
  />
  <ZoomIn 
    className="w-6 h-6 cursor-pointer" 
    onClick={() => handleZoomChange(zoom + 10)} 
  />
  <div className="ml-10 flex space-x-2">
  <Button onClick={toggleAllGroups} variant="outline" size="sm">
            {areAllGroupsExpanded ? <Shrink className="w-4 h-4 mr-2" /> : <Expand className="w-4 h-4 mr-2" />}
            {areAllGroupsExpanded ? 'Colapsar Todos' : 'Expandir Todos'}
          </Button>
          <Button onClick={toggleFullScreen} variant="outline" size="sm">
            {isFullScreen ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
            {isFullScreen ? 'Salir de Pantalla Completa' : 'Pantalla Completa'}
          </Button>
  </div>


        </div>
        <select
          value={viewMode}
          onChange={handleViewModeChange}
          className="border p-2 rounded-md bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value={ViewMode.Day}>Día</option>
          <option value={ViewMode.Week}>Semana</option>
          <option value={ViewMode.Month}>Mes</option>
          <option value={ViewMode.Year}>Año</option>
        </select>
      </div>
      <div 
        ref={ganttRef}
        className="flex-grow overflow-auto"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <Gantt
            tasks={chartData}
            viewMode={viewMode}
            locale="es"
            listCellWidth="250px"
            columnWidth={columnWidth}
            ganttHeight={Math.max(isFullScreen ? window.innerHeight - 150 : 391)}
            headerHeight={50}
            rowHeight={40}
            barCornerRadius={5}
            projectProgressColor="#3B82F6"
            barProgressColor="#F59E0B"
            barProgressSelectedColor="#FBBF24"
            barBackgroundColor="#E5E7EB"
            barBackgroundSelectedColor="#D1D5DB"
            handleWidth={0}
            timeStep={86400000}
            arrowColor="#6B7280"
            fontFamily="Inter, sans-serif"
            fontSize="14px"
            weekPrefix=""
            todayColor="rgba(239, 68, 68, 0.1)"
            todayLineColor="rgba(239, 68, 68, 0.9)"
            TaskListHeader={CustomHeader}
            TaskListTable={CustomTaskListTable}
            TooltipContent={({ task }) => (
              <div className="bg-white p-3 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">{task.name}</h3>
                <p className="mb-1"><span className="font-semibold">Inicio:</span> {formatDate(task.start)}</p>
                <p className="mb-1"><span className="font-semibold">Fin:</span> {formatDate(task.end)}</p>
                <p className="mb-1"><span className="font-semibold">Progreso:</span> {task.progress.toFixed(2)}%</p>
                <p><span className="font-semibold">Estado:</span> {
                  task.styles?.progressColor === '#E5E7EB' ? 'No iniciada' :
                  task.styles?.progressColor === '#4ADE80' ? 'En progreso' :
                  task.styles?.progressColor === '#FDE047' ? 'Por vencer' :
                  task.styles?.progressColor === '#FB923C' ? 'A punto de vencer' :
                  task.styles?.progressColor === '#EF4444' ? 'Vencida' :
                  'Completada'
                }</p>
              </div>
            )}
            onDateChange={() => {}}
            onProgressChange={() => {}}
            onDoubleClick={() => {}}
            onSelect={handleTaskSelect}
            onExpanderClick={handleExpanderClick}
          />
        </div>
      </div>
    </div>
  );
};
export default GanttChartView;

